@if(wishlistItems){
  <div class='wishlist-wrapper' [class.edit-mode]='isEdit' [class.one-column]='event?.eventTemplate?.layout === EventTemplateLayout.ONE_COLUMN_MODERN'>
    @if(isEdit){
      <div class='editable-items'
           cdkDropList
           cdkDropListOrientation="horizontal"
           (cdkDropListDropped)="drop($event, draggedWishlistItem)">
        <div class='add-item-button' (click)="onConfigureSection()">
          <i class="far fa-plus"></i>
        </div>
        <div
          *ngFor="let item of wishlistItems; trackBy: trackByFn"
          class="editable-item"
          cdkDrag
          (cdkDragStarted)="draggedWishlistItem = item"
        >
          <app-event-section-wishlist-item [item]="item" />
          <div cdkDragHandle class="grab-action">
            <i class="fas fa-grip-vertical"></i>
          </div>
          <div class="update-actions-menu" [matMenuTriggerFor]="menu">
            <div class="menu-trigger">
              <i class="far fa-ellipsis-v"></i>
            </div>
            <mat-menu #menu="matMenu" class="table-actions-container">
              <button
                mat-menu-item
                (click)="onUpdate(UpdateActions.EDIT, item)"
              >
                <span class="menu-item-content">
                  <i class="far fa-pen"></i>
                  <span class="text">{{
                      "APP.EDIT" | translate | camelCase
                    }}</span>
                </span>
              </button>

              <button
                mat-menu-item
                (click)="onUpdate(UpdateActions.COPY, item)"
              >
                <span class="menu-item-content">
                  <i class="far fa-copy"></i>
                  <span class="text">{{
                      "APP.COPY" | translate | camelCase
                    }}</span>
                </span>
              </button>

              <button
                mat-menu-item
                (click)="onUpdate(UpdateActions.DELETE, item)"
              >
                <span class="menu-item-content">
                  <i class="far fa-trash"></i>
                  <span class="text">{{
                      "APP.DELETE" | translate | camelCase
                    }}</span>
                </span>
              </button>
            </mat-menu>
          </div>
        </div>
      </div>

    }@else{
      <swiper-container
        appSwiper
        [config]="swiperConfig"
        init="false"
        class="wishlist-swiper"
      >
        @for (item of wishlistItems; track item.id) {
          <swiper-slide (click)='goToLink(item)'>
            <img [src]='item.pictureUrl' class='wishlist-item' alt=''/>
          </swiper-slide>
        }
      </swiper-container>
    }
  </div>
}
