import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventTemplateVisual } from '@models/design-templates/event-template-visual.model';
import { EventType } from '@models/events/event-type.enum';
import { Event } from '@models/events/event.model';
import { TranslateModule } from '@ngx-translate/core';
import moment from 'moment/moment';
import { AppRoutes } from '../../../../routes';

@Component({
  selector: 'app-event-section-event-list-item',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './event-section-event-list-item.component.html',
  styleUrl: './event-section-event-list-item.component.scss',
})
export class EventSectionEventListItemComponent implements OnInit {
  @Input() event?: Event;
  isInThePast: boolean = false;

  get visual(): EventTemplateVisual | undefined {
    return this.event?.eventTemplate?.visual;
  }

  constructor(private router: Router) {}
  formatDate(date?: Date) {
    if (!date) {
      return null;
    }
    const createdAtMoment = moment(date);
    return createdAtMoment.format('DD.MM.YY');
  }

  ngOnInit(): void {
    if (this.event && this.event.startDate) {
      this.isInThePast = this.isDateBeforeNow(this.event?.startDate);
    }
  }

  isDateBeforeNow(date: Date): boolean {
    const now = new Date();
    return date < now;
  }

  formatTime(date?: Date) {
    if (!date) {
      return null;
    }
    const createdAtMoment = moment(date);
    return createdAtMoment.format('HH:mm');
  }

  goToEvent() {
    if (this.event) {
      this.router
        .navigate(['/', AppRoutes.Events.events, this.event.uri])
        .then(() => {
          location.reload();
        });
    }
  }

  get isDefaultGeneric(): boolean {
    return this.event?.type === EventType.GENERIC_EVENT;
  }

  get isUaEvent(): boolean {
    return !!this.event?.uri.includes('underarmour-infinite-elite-testival');
  }
}
