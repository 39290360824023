@if (event && section) {
  <div
    class="event-section-wrapper"
    [class.edit-mode]="isEdit"
    [class.accent-section]="isAccentSection"
    [ngStyle]="{
      'background-color':
        visualBackgroundUrl && section.type === EventSectionType.MAIN
          ? 'transparent'
          : isSubSection
            ? parentSection?.backgroundColor
            : section.backgroundColor,
      color: isSubSection ? parentSection?.bodyColor : section.bodyColor,
      '--section-body-color': isSubSection
        ? parentSection?.bodyColor
        : section.bodyColor
    }"
  >
    <div
      class="event-section"
      data-aos-duration="1000"
      data-aos-offset="0"
      data-aos-anchor-placement="top-bottom"
      [attr.data-aos]="isFadeUp ? 'fade-up' : null"
      [class.has-color]="section.bodyColor && section.bodyColor.trim() !== ''"
      [class.logged-user-view]="loggedUser"
      [class.wedding-page-section]="isWeddingPage"
      [class.edit-mode]="isEdit"
      [class.ua-section]="isUaSection"
      [class.host-view]="event.isUserHost(loggedUser?.id)"
      [class.main-section]="section.type === EventSectionType.MAIN"
      [class.normal-section]="section.type === EventSectionType.NORMAL"
      [class.with-image]="section.imageUrl && section.imageUrl.length > 1"
      [class.timeline-section]="section.type === EventSectionType.TIMELINE"
      [class.wishlist-section]="section.type === EventSectionType.WISHLIST"
      [class.footer-section]="section.type === EventSectionType.FOOTER"
      [class.faq-section]="section.type === EventSectionType.FAQ"
      [class.gallery-section]="section.type === EventSectionType.GALLERY"
      [class.groomsmen-section]="section.type === EventSectionType.GROOMSMEN"
      [class.elegant]="layout === EventTemplateLayout.ONE_COLUMN_ELEGANT"
      [class.modern-one-column]="
        layout === EventTemplateLayout.ONE_COLUMN_MODERN
      "
      [class.display-none]="
        layout === EventTemplateLayout.ONE_COLUMN_MODERN &&
        !isEdit &&
        section.type === EventSectionType.TIMELINE &&
        eventTimetable?.items?.length === 0
      "
      [class.medium]="
        section.backgroundSize === EventSectionBackgroundSize.MEDIUM
      "
      [class.small]="
        section.backgroundSize === EventSectionBackgroundSize.SMALL
      "
      [class.all-map]="
        section.type === EventSectionType.LOCATION &&
        event.type === EventType.LANDINGPAGE
      "
      [class.sub-section]="isSubSection"
      [class.accent-section]="isAccentSection"
      [class.sport-page]="isDarkThemeDesign"
      [class.testimonials-section]="
        section.type === EventSectionType.TESTIMONIALS
      "
      [class.sponsors-section]="section.type === EventSectionType.SPONSORS"
      [class.location-section]="
        section.type === EventSectionType.LOCATION &&
        event.type === EventType.SPORTPAGE
      "
      [class.location-section-modern]="
        section.type === EventSectionType.LOCATION &&
        layout === EventTemplateLayout.ONE_COLUMN_MODERN
      "
      [class.about-section]="!!section.backgroundImageUrl"
      [class.full-width]="isDarkThemeDesign"
      [class.sport-page-event]="event.type === EventType.SPORTPAGE"
      [class.centered]="section.type === EventSectionType.DATE_LOCATION"
      [class.max-width-unset]="
        section.type === EventSectionType.SPONSORS ||
        (section.type === EventSectionType.LOCATION &&
          layout !== EventTemplateLayout.ONE_COLUMN_ELEGANT) ||
        section.type === EventSectionType.TESTIMONIALS
      "
      [ngStyle]="{
        'background-color':
          visualBackgroundUrl && section.type === EventSectionType.MAIN
            ? 'transparent'
            : isSubSection
              ? parentSection?.backgroundColor
              : section.backgroundColor,
        color: isSubSection ? parentSection?.bodyColor : section.bodyColor
      }"
      [attr.id]="getAttrId(section.type)"
    >
      @if (isEdit && !isSubSection) {
        <div class="colors-toggle">
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            class="btn btn-options"
            (click)="$event.stopPropagation()"
          >
            <i class="far fa-ellipsis-v"></i>
          </button>
        </div>
        <mat-menu #menu="matMenu" class="table-actions-container">
          <button
            mat-menu-item
            ngx-colors-trigger
            [format]="'hex'"
            [ngModel]="section.backgroundColor"
            (ngModelChange)="onColorChange($event, 'backgroundColor')"
            (click)="$event.stopPropagation(); openPalette()"
          >
            <div
              class="option"
              [ngStyle]="{
                display: 'flex',
                gap: '10px',
                'align-items': 'center'
              }"
            >
              <i class="fa-regular fa-fill-drip"></i>
              <span>{{
                "APP.SECTION.CHANGE_BACKGROUND_COLOR" | translate
              }}</span>
            </div>
          </button>
          <button
            mat-menu-item
            ngx-colors-trigger
            [format]="'hex'"
            [ngModel]="section.bodyColor"
            (ngModelChange)="onColorChange($event, 'bodyColor')"
            (click)="$event.stopPropagation(); openPalette()"
          >
            <div
              class="option"
              [ngStyle]="{
                display: 'flex',
                gap: '10px',
                'align-items': 'center'
              }"
            >
              <i class="fa-regular fa-droplet"></i>
              <span>{{ "APP.SECTION.CHANGE_TEXT_COLOR" | translate }}</span>
            </div>
          </button>
        </mat-menu>
        <div #colorPickerTrigger></div>
      }
      @if (section.mainIcon && section.mainIcon !== "") {
        @if (isUaSection) {
          <div class="icons-cont">
            <div
              class="event-section-main-icon"
              [class.big-icon]="isDarkThemeDesign"
            >
              <img [src]="section.mainIcon" alt="Main Section Icon" />
            </div>
            <div class="event-section-sponsor-icon">
              <img
                src="https://static.eventpage.ai/event-templates/event-templates-1594/sportscheck-logo.svg"
                alt="Main Section Sponsor"
              />
            </div>
          </div>
        } @else {
          <div
            class="event-section-main-icon"
            [class.big-icon]="isDarkThemeDesign"
          >
            <img [src]="section.mainIcon" alt="Main Section Icon" />
          </div>
        }
      }
      @if (
        ((section.description && section.description !== "") || isSubSection) &&
        layout === EventTemplateLayout.ONE_COLUMN_MODERN &&
        (section.type === EventSectionType.NORMAL ||
          section.type === EventSectionType.MAIN)
      ) {
        @if (section.imageUrl && section.imageUrl !== "") {
          <div
            class="description-with-image"
            [class.revert-row]="section.imagePosition === ImagePosition.RIGHT"
          >
            <img
              [src]="section.imageUrl"
              alt="Image"
              (click)="isEdit && openImagePicker(true)"
              [class.edit-view]="isEdit"
            />
            <ng-container
              *ngTemplateOutlet="
                descriptionTemplate;
                context: { section: section }
              "
            ></ng-container>
          </div>
        } @else {
          <ng-container
            *ngTemplateOutlet="
              descriptionTemplate;
              context: { section: section }
            "
          ></ng-container>
        }
      }

      @if (section.mainTitle && section.mainTitle !== "") {
        <div class="event-section-main-title" [class.edit-mode]="isEdit">
          @if (isEdit) {
            <app-rich-text-editor
              class="main-title"
              [section]="section"
              [isRSVPSection]="true"
              [bodyColor]="getSectionColor()"
              [sectionProperty]="'mainTitle'"
              [valueToChange]="section.mainTitle"
              (updateValue)="saveText($event, 'mainTitle')"
            />
          } @else {
            @if (isDarkThemeDesign) {
              {{ section.mainTitle | uppercase }}
            } @else {
              <div
                class="main-title"
                [innerHTML]="section.mainTitle | safeHtml"
              ></div>
            }
          }
        </div>
        @if (
          event.type === EventType.SPORTPAGE &&
          section.type === EventSectionType.MAIN
        ) {
          <div class="location-section location-section-title">
            <div class="location" [innerHTML]="locationsSafeHtml"></div>
          </div>
        }
      }
      @if (section.headline && section.headline !== "") {
        @if (isEdit) {
          @if (layout === EventTemplateLayout.ONE_COLUMN_ELEGANT) {
            <div class="elegant-headline-section">
              @if (!isOver380) {
                <div
                  class="line"
                  [ngStyle]="{
                    'background-color': isSubSection
                      ? parentSection?.bodyColor
                      : section.bodyColor
                  }"
                ></div>
              }
              <div class="title edit">
                <app-rich-text-editor
                  class="event-section-headline"
                  [section]="section"
                  [isRSVPSection]="true"
                  [bodyColor]="getSectionColor(true)"
                  [sectionProperty]="'headline'"
                  [valueToChange]="section.headline"
                  (updateValue)="saveText($event, 'headline')"
                />
              </div>
              <div
                class="line"
                [ngStyle]="{
                  'background-color': section.bodyColor
                }"
              ></div>
            </div>
          } @else {
            <app-rich-text-editor
              class="event-section-headline"
              [section]="section"
              [isRSVPSection]="true"
              [bodyColor]="getSectionColor(true, true)"
              [sectionProperty]="'headline'"
              [valueToChange]="section.headline"
              (updateValue)="saveText($event, 'headline')"
            />
          }
        } @else {
          @if (isDarkThemeDesign || isWeddingPage) {
            @if (layout === EventTemplateLayout.ONE_COLUMN_ELEGANT) {
              <div class="elegant-headline-section">
                @if (!isOver380) {
                  <div
                    class="line"
                    [ngStyle]="{
                      'background-color': section.bodyColor
                    }"
                  ></div>
                }
                <div class="title">
                  <div
                    class="event-section-headline"
                    [ngStyle]="{
                      color: isSubSection
                        ? parentSection?.bodyColor
                        : section.bodyColor,
                      'white-space': 'pre-wrap'
                    }"
                    [innerHtml]="section.headline | safeHtml"
                  ></div>
                </div>
                <div
                  class="line"
                  [ngStyle]="{
                    'background-color': section.bodyColor
                  }"
                ></div>
              </div>
            } @else {
              <div
                class="event-section-headline"
                [ngStyle]="{
                  color: isSubSection
                    ? parentSection?.bodyColor
                    : section.bodyColor
                }"
                [innerHTML]="section.headline | safeHtml"
              ></div>
            }
          } @else {
            <div
              class="event-section-headline"
              [ngStyle]="{
                color: isSubSection
                  ? parentSection?.bodyColor
                  : section.bodyColor
              }"
            >
              {{ section.headline }}
            </div>
          }
        }
      }
      @if (section.imageUrl && section.imagePosition === ImagePosition.TOP) {
        <div class="media-wrapper" [class.edit-view]="isEdit">
          <img
            [src]="section.imageUrl"
            class="image"
            alt="Section Url"
            (click)="isEdit && openImagePicker(true)"
          />
          @if (isEdit) {
            <div class="edit-action-bar">
              <i class="fa fa-upload"></i>
            </div>
          }
        </div>
      }
      @if (section.title && section.title !== "") {
        @if (isEdit) {
          <app-rich-text-editor
            class="event-section-title"
            [section]="section"
            [class.elegant]="layout === EventTemplateLayout.ONE_COLUMN_ELEGANT"
            [isRSVPSection]="true"
            [bodyColor]="getSectionColor()"
            [sectionProperty]="'title'"
            [valueToChange]="section.title"
            (updateValue)="saveText($event, 'title')"
          />
        } @else {
          @if (isDarkThemeDesign || isWeddingPage) {
            <div
              class="event-section-title"
              [ngStyle]="{
                color: getSectionColor(),
                'white-space': 'pre-wrap'
              }"
              [class.elegant]="
                layout === EventTemplateLayout.ONE_COLUMN_ELEGANT
              "
              [innerHtml]="section.title | safeHtml"
            ></div>
          } @else {
            <div class="event-section-title">
              {{ section.title }}
            </div>
          }
        }
      }

      @if (section.type === EventSectionType.MAIN && isUaSection) {
        <img
          src="https://static.eventpage.ai/event-templates/event-templates-1594/graphic-peace.png"
          class="ua-ran-too-far-graphic"
          [class.landing-graphic]="event.type === EventType.LANDINGPAGE"
        />
      }

      @if (
        section.type === EventSectionType.MAIN &&
        isWeddingPage &&
        layout === EventTemplateLayout.ONE_COLUMN_MODERN
      ) {
        <div
          class="date modern"
          (click)="isEdit && onChangeDate()"
          [class.editable]="isEdit"
        >
          <app-event-start-date-timer [startDate]="event.startDate" />
        </div>
        @if (isEdit) {
          <div
            class="to-rsvp-modern"
            [ngStyle]="{
              'border-color': section.bodyColor
            }"
          >
            <div
              text-editable
              required
              [attr.placeholder]="'APP.EVENT_RSVP.TITLE' | translate"
              [maxlength]="1000"
              #descriptionDiv="ngModel"
              [ngModel]="event.actionSectionTitle"
              (ngModelChange)="onSectionActionTitleChange($event)"
              (paste)="onPasteText($event)"
              [innerText]="event.actionSectionTitle"
              style="white-space: pre-wrap"
            >
              @if (event.actionSectionTitle === "") {
                {{ "APP.EVENT_RSVP.TITLE" | translate }}
              } @else {
                {{ event.actionSectionTitle }}
              }
            </div>
          </div>
        } @else {
          <div
            class="to-rsvp-modern"
            (click)="rsvp()"
            [ngStyle]="{
              'border-color': section.bodyColor
            }"
          >
            {{ event.actionSectionTitle || "APP.EVENT_RSVP.TITLE" | translate }}
          </div>
        }
      }
      @if (
        section.type === EventSectionType.LOCATION &&
        (event.hasAddress() ||
          event.hasLatLng() ||
          event.type === EventType.LANDINGPAGE ||
          event.type === EventType.WEDDINGPAGE) &&
        !isServer
      ) {
        @if (isDarkThemeDesign) {
          <app-event-section-map style="height: 100%"></app-event-section-map>
        } @else {
          @if (layout === EventTemplateLayout.ONE_COLUMN_ELEGANT) {
            <div
              class="inline-address"
              [class.edit-mode]="isEdit"
              (click)="isEdit && openPickerDialog()"
              [class.elegant]="
                layout === EventTemplateLayout.ONE_COLUMN_ELEGANT
              "
            >
              {{
                event.eventAddress
                  ? event.getAddressDisplayName()
                  : ("APP.EVENT.LOCATION_TBD" | translate)
              }}
            </div>
            <div class="navigate-action" (click)="openGoogleMaps()">
              <div class="action">Google Maps</div>
              <i class="far fa-arrow-right"></i>
            </div>
          } @else {
            @if (isEdit) {
              <div class="w-100" (click)="openPickerDialog()">
                <app-event-section-map
                  [bodyColor]="section.bodyColor"
                  [editView]="true"
                ></app-event-section-map>
              </div>
            } @else {
              <app-event-section-map
                [bodyColor]="section.bodyColor"
              ></app-event-section-map>
            }
          }
        }
      }

      @if (section.type === EventSectionType.DATE_LOCATION) {
        <app-event-section-location-date [event]="event" [section]="section" />
      }

      @if (
        section.description &&
        section.description !== "" &&
        ((layout === EventTemplateLayout.ONE_COLUMN_MODERN &&
          section.type !== EventSectionType.MAIN &&
          section.type !== EventSectionType.NORMAL &&
          section.type !== EventSectionType.WISHLIST) ||
          layout !== EventTemplateLayout.ONE_COLUMN_MODERN)
      ) {
        @if (isEdit) {
          <app-rich-text-editor
            class="event-section-description"
            [class.elegant]="layout === EventTemplateLayout.ONE_COLUMN_ELEGANT"
            [section]="section"
            [isRSVPSection]="true"
            [bodyColor]="getSectionColor()"
            [sectionProperty]="'description'"
            [valueToChange]="section.description"
            (updateValue)="saveText($event, 'description')"
          />
        } @else {
          @if (isDarkThemeDesign || isWeddingPage) {
            <div
              [class.elegant]="
                layout === EventTemplateLayout.ONE_COLUMN_ELEGANT
              "
              class="event-section-description"
              [ngStyle]="{ 'white-space': 'pre-wrap' }"
              [innerHtml]="
                QuillUtils.replaceEmptyPTags(section.description) | safeHtml
              "
            ></div>
          } @else {
            <div
              [class.elegant]="
                layout === EventTemplateLayout.ONE_COLUMN_ELEGANT
              "
              class="event-section-description"
              [innerText]="section.description"
            ></div>
          }
        }
      }

      @if (section.imageUrl && section.imagePosition === ImagePosition.BOTTOM) {
        <div class="media-wrapper bottom" [class.edit-view]="isEdit">
          <img
            [src]="section.imageUrl"
            class="image"
            alt="Section Url"
            (click)="isEdit && openImagePicker(true)"
          />
          @if (isEdit) {
            <div class="edit-action-bar">
              <i class="fa fa-upload"></i>
            </div>
          }
        </div>
      }

      @if (
        section.type === EventSectionType.MAIN &&
        layout === EventTemplateLayout.ONE_COLUMN_ELEGANT
      ) {
        <app-event-start-date-timer
          [startDate]="event.startDate"
          class="w-100 timer-section"
        />
        <app-heart-icon
          [fillColor]="
            section.bodyColor || 'var(--event-section-headline-color)'
          "
        />
      }

      @if (section.type === EventSectionType.WISHLIST) {
        <app-event-section-wishlist
          [event]="event"
          [isEdit]="isEdit"
          (onEdit)="onConfigureSection($event)"
          (configureSection)="onConfigureSection()"
          class="wishlist-items"
        />
      }

      @if (
        section.description &&
        section.description !== "" &&
        layout === EventTemplateLayout.ONE_COLUMN_MODERN &&
        section.type !== EventSectionType.MAIN &&
        section.type !== EventSectionType.NORMAL
      ) {
        <div class="description-section">
          <ng-container
            *ngTemplateOutlet="
              descriptionTemplate;
              context: { section: section }
            "
          ></ng-container>
        </div>
      }

      @if (section.type === EventSectionType.TIMELINE) {
        @if (isDarkThemeDesign) {
          <app-event-section-timetable
            [class.program-section]="
              isDarkThemeDesign && event.type === EventType.LANDINGPAGE
            "
            [eventTimetable]="eventTimetable"
            [eventType]="event.type"
            [isUaEvent]="isUaSection"
            [isEdit]="isEdit"
            (onEdit)="onConfigureSection($event)"
          ></app-event-section-timetable>
        } @else {
          <app-event-section-timetable
            [layout]="layout"
            [eventTimetable]="eventTimetable"
            [isWeddingPage]="isWeddingPage"
            [isEdit]="isEdit"
            [bodyColor]="getSectionColor()"
            (onEdit)="onConfigureSection($event)"
          ></app-event-section-timetable>
        }
        @if (isEdit) {
          <div
            class="actions-section-dashed"
            (click)="
              onConfigureSection({
                day: event.startDate
              })
            "
          >
            {{ "APP.SECTION.ADD_PROGRAM_ITEM" | translate }}
          </div>
        }
      }

      @if (
        section.type === EventSectionType.SPONSORS &&
        event.eventTemplate?.eventSponsors
      ) {
        <app-event-section-sponsors
          [sponsors]="event.eventTemplate?.eventSponsors"
        />
      }

      @if (
        section.type === EventSectionType.TESTIMONIALS &&
        event.eventTemplate?.eventTestimonials
      ) {
        <app-event-section-testimonials
          [event]="event"
          [isEdit]="isEdit"
          [testimonials]="event.eventTemplate?.eventTestimonials"
          (onEdit)="onConfigureSection($event)"
          (configureSection)="onConfigureSection()"
        />
      }

      @if (
        section.type === EventSectionType.EVENT_LIST &&
        event.type === EventType.LANDINGPAGE
      ) {
        <app-event-section-event-list [event]="event" />
      }

      @if (section.type === EventSectionType.GROOMSMEN && eventWeddingParty) {
        <app-event-section-wedding-party
          [eventWeddingPartyItems]="eventWeddingParty.items"
          [event]="event"
          [isEdit]="isEdit"
          (onEdit)="onConfigureSection($event)"
          (configureSection)="onConfigureSection()"
          class="w-100"
        ></app-event-section-wedding-party>
      }

      @if (section.type === EventSectionType.FAQ && eventFaqs) {
        <app-event-section-faq
          [class.elegant]="layout === EventTemplateLayout.ONE_COLUMN_ELEGANT"
          [faqs]="eventFaqs"
          [event]="event"
          [isEdit]="isEdit"
          [layout]="layout"
          [bodyColor]="getSectionColor()"
          (onEdit)="onConfigureSection($event)"
        ></app-event-section-faq>
        @if (isEdit) {
          @if (isEdit) {
            <div class="actions-section-dashed" (click)="onConfigureSection()">
              <i class="far fa-plus"></i>
            </div>
          }
        }
      }

      @if (section.type === EventSectionType.GALLERY) {
        <app-event-section-gallery
          class="w-100"
          [isEdit]="isEdit"
          (onEdit)="onConfigureSection($event)"
        />
        @if (isEdit) {
          <div class="actions-section-dashed" (click)="onConfigureSection()">
            <i class="far fa-plus"></i>
          </div>
        }
        <!--     <div class='navigate-action'>
          <div class='action'>Alles Ansehen</div>
          <i class="far fa-arrow-right"></i>
        </div>-->
      }

      @if (
        section.type === EventSectionType.RSVP &&
        (event.canRsvp() || event.isUserHost(loggedUser?.id))
      ) {
        <app-event-section-rsvp
          [loggedUser]="loggedUser"
        ></app-event-section-rsvp>
      }

      @if (section.subSections && section.subSections.length > 0) {
        @for (
          subSection of section.getSubSectionsOrdered();
          track subSection.id
        ) {
          <app-event-section
            [parentSection]="section"
            [section]="subSection"
            [isSubSection]="true"
            [layout]="layout"
            [isEdit]="isEdit"
            class="w-100"
          ></app-event-section>
        }
      }

      @if (
        section.type === EventSectionType.MAIN &&
        event.type === EventType.LANDINGPAGE
      ) {
        @if (isUaSection) {
          <div class="btn-section ua-section">
            <button class="btn btn-primary" (click)="onFindEvent()">
              <i class="far fa-calendar-alt"></i>
              {{ "APP.SPORT_PAGE.FIND_YOUR_DATE" | translate | uppercase }}
            </button>
            <button class="btn btn-secondary" (click)="onLearnMore()">
              <i class="far fa-running"></i>
              {{ "APP.SPORT_PAGE.LEARN_MORE" | translate | uppercase }}
            </button>
          </div>
        } @else {
          <div class="btn-section">
            <button class="btn btn-primary" (click)="onFindEvent()">
              {{ "APP.SPORT_PAGE.FIND_YOUR_EVENT" | translate | uppercase }}
            </button>
            <button class="btn btn-secondary" (click)="onLearnMore()">
              {{ "APP.SPORT_PAGE.LEARN_MORE" | translate | uppercase }}
            </button>
          </div>
        }
      }
      @if (
        (section.type === EventSectionType.TIMELINE &&
          event.type === EventType.SPORTPAGE) ||
        (section.type === EventSectionType.MAIN &&
          event.type === EventType.SPORTPAGE)
      ) {
        <div class="btn-section" [class.ua-section]="isUaSection">
          <app-event-rsvp
            [loggedUserId]="loggedUser?.id"
            [isLoggedUserHost]="
              loggedUser &&
              loggedUser.id &&
              (event.isUserHost(loggedUser.id) || loggedUser.isAdmin())
            "
            [showGuestCount]="event.showGuestCount"
            [isAttendeeView]="isAttendeeView"
          ></app-event-rsvp>
        </div>
      }

      @if (!!section.backgroundImageUrl && isUaSection) {
        <div class="about-button-section">
          <button class="btn btn-ua" (click)="goToUaWebsite()">
            <object
              data="https://static.eventpage.ai/event-templates/event-templates-1594/logo-icon.svg"
              type="image/svg+xml"
            ></object>
            {{ "APP.SPORT_PAGE.GO_TO_WEBSITE" | translate | uppercase }}
          </button>
          <div class="about-circles">
            <object
              data="https://static.eventpage.ai/event-templates/event-templates-1594/graphic_circle.svg"
              type="image/svg+xml"
            ></object>
            <object
              data="https://static.eventpage.ai/event-templates/event-templates-1594/graphic_circle.svg"
              type="image/svg+xml"
            ></object>
            <object
              data="https://static.eventpage.ai/event-templates/event-templates-1594/graphic_circle.svg"
              type="image/svg+xml"
            ></object>
          </div>
        </div>
      }
    </div>
  </div>
}
@if (section?.type === EventSectionType.MAIN && visual) {
  <div
    class="background-overlay"
    [style.backgroundColor]="
      visual.backgroundColor ? visual.backgroundColor : ''
    "
    [style.background-image]="
      visualBackgroundUrl && visual.isBackgroundImage()
        ? 'url(' + visualBackgroundUrl + ')'
        : ''
    "
  >
    @if (visualBackgroundUrl && visual.isBackgroundVideo()) {
      <div class="background-video-cont">
        <video
          #backgroundVideo
          class="background-video"
          [muted]="'muted'"
          [class.host-view]="isHostView"
          autoplay
          playsinline
          loop
        >
          @if (visualBackgroundHevcUrl !== "") {
            <source
              [src]="visualBackgroundHevcUrl"
              type="video/mp4;codecs=hvc1"
            />
          }
          <source
            [src]="visualBackgroundUrl"
            [type]="visual.background?.mimeType || 'video/mp4'"
          />
        </video>
      </div>
    }
  </div>
}

<ng-template #descriptionTemplate let-section="section">
  @if (isEdit) {
    <app-rich-text-editor
      class="event-section-description"
      [section]="section"
      [isRSVPSection]="true"
      [bodyColor]="getSectionColor()"
      [sectionProperty]="'description'"
      [valueToChange]="section?.description"
      (updateValue)="saveText($event, 'description')"
    />
  } @else {
    @if (isDarkThemeDesign || isWeddingPage) {
      <div
        class="event-section-description"
        [ngStyle]="{ 'white-space': 'pre-wrap' }"
        [innerHtml]="
          QuillUtils.replaceEmptyPTags(section.description) | safeHtml
        "
      ></div>
    } @else {
      <div
        class="event-section-description"
        [innerText]="section.description"
      ></div>
    }
  }
</ng-template>
