@if (testimonial) {
  <div
    class="testimonial-wrapper"
    [class.generic-testimonial]="isGenericLayout()"
    [class.advanced-one-column-testimonial]="
      event && event.type === EventType.ADVANCED_ONE_COLUMN
    "
  >
    <div class="avatar">
      <img [src]="testimonial.userAvatarUrl" class="image" />
      @if(isGenericLayout()){
        <div class="user-name">
          {{ testimonial.userName }}
          @if(testimonial.userCity){
            , <span class="secondary">{{testimonial.userCity}}</span>
          }
        </div>
      }
    </div>
    <div class="user-data">
      @if(!isGenericLayout()){
        <div class="user-name">
          {{ testimonial.userName }}
        </div>
      }@else{
        <div class="quotation"> &#x201F;</div>
      }


      @if (testimonial.userCity && testimonial.userJobTitle && event && event.type !== EventType.ADVANCED_ONE_COLUMN) {
        <div class="user-data-description">
          {{ testimonial.userJobTitle }},{{ testimonial.userCity }}
        </div>
      }

      @if (
        event &&
        event.type === EventType.ADVANCED_ONE_COLUMN &&
        testimonial.userJobTitle
      ) {
        <div class="user-data-description">
          {{ testimonial.userJobTitle }}
        </div>
      }

      @if (testimonial.description) {
        <div class="description">
          {{ getDescription() }}
        </div>
      }
    </div>
  </div>
}
