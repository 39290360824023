@if (faqs) {
  <cdk-accordion
    class="example-accordion"
    cdkDropList
    (cdkDropListDropped)="isEdit && drop($event, draggedFaq)"
    [class.sport-page-wrapper]="
      event?.type === EventType.LANDINGPAGE ||
      event?.type === EventType.SPORTPAGE
    "
    [class.advanced-one-column-wrapper]="
      event?.type === EventType.ADVANCED_ONE_COLUMN
    "
    [class.dashed-style-wrapper]="
      event?.type === EventType.WEDDINGPAGE
    "
    [class.ua-section]="isUaSection"
    [class.borderless]='layout === EventTemplateLayout.ONE_COLUMN_ELEGANT || layout === EventTemplateLayout.ONE_COLUMN_MODERN'
  >
    @for (faq of faqs; track faq.id) {
        <cdk-accordion-item
          cdkDrag
          #accordionItem="cdkAccordionItem"
          class="example-accordion-item"
          [class.preview]="!isEdit"
          [class.sport-page-border-item]="
          event?.type === EventType.LANDINGPAGE ||
          event?.type === EventType.SPORTPAGE
        "
          role="button"
          tabindex="0"
          [attr.id]="'accordion-header-' + $index"
          [attr.aria-expanded]="accordionItem.expanded"
          [attr.aria-controls]="'accordion-body-' + $index"
          (cdkDragStarted)="isEdit && (draggedFaq = faq)"
        >
          @if (isEdit) {
            <div cdkDragHandle class="grab-action">
              <i class="fas fa-grip-vertical"></i>
            </div>
          }
          <div
            class="example-accordion-item-header"
            (click)="accordionItem.toggle()"
          >
            {{ faq.question }}
            <div class="actions"
                 [ngStyle]="{
                  'color': bodyColor,
                }">
              @if (isEdit) {
                <span (click)="update($event, UpdateActions.EDIT, faq)">
                <i class="far fa-pen"></i>
              </span>
                <span (click)="update($event, UpdateActions.DELETE, faq)">
                <i class="far fa-trash"></i>
              </span>
              }
              <span class="example-accordion-item-arrow">
              @if (event?.type === EventType.ADVANCED_ONE_COLUMN) {
                <i
                  class="far fa-chevron-{{
                    accordionItem.expanded ? 'up' : 'down'
                  }}"
                ></i>
              }
              @else if((layout ===EventTemplateLayout.ONE_COLUMN_ELEGANT || layout ===EventTemplateLayout.ONE_COLUMN_MODERN) && event?.type === EventType.WEDDINGPAGE){
                <i
                  class="far fa-{{
                    accordionItem.expanded ? 'minus' : 'plus'
                  }}"
                ></i>
              }
              @else {
                <i
                  class="fas fa-caret-{{
                    accordionItem.expanded ? 'up' : 'down'
                  }}"
                ></i>
              }
            </span>
            </div>
          </div>
          <div
            class="example-accordion-item-body"
            role="region"
            [style.display]="accordionItem.expanded ? '' : 'none'"
            [attr.id]="'accordion-body-' + $index"
            [attr.aria-labelledby]="'accordion-header-' + $index"
            [innerHtml]="faq.answer | safeHtml"
          ></div>
        </cdk-accordion-item>

    }
  </cdk-accordion>
}
