import { Component, Input } from '@angular/core';
import { User } from '@models/users/user.model';
import { EventStore } from '@services/stores/event.store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Event } from '@models/events/event.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { EventRsvpQuestionComponent } from '../event-rsvp-question/event-rsvp-question.component';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
} from '@angular/forms';
import { FormsModules } from '@utils/shared-modules';
import { TelInputComponent } from '@modules/shared/components/tel-input/tel-input.component';
import {
  EventRSVPRequest,
  RSVPGroupMemberData,
  RSVPQuestionAnswer,
} from '@models/events/dto/event-rsvp.request';
import { RSVPOptionType } from '@models/event-attendance/rsvp-option-type.enum';
import { EventService } from '@services/events/event.service';
import { NotificationService } from '@services/shared/notification.service';
import { QuestionType } from '@models/event-attendance/event-attendee-question-type.enum';
import { EventAfterAttendOverlayDialogComponent } from '@modules/events/dialogs/event-after-attend-overlay-dialog/event-after-attend-overlay-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-event-section-rsvp',
  standalone: true,
  imports: [
    CommonModule,
    FormsModules,
    TranslateModule,
    EventRsvpQuestionComponent,
    TelInputComponent,
  ],
  providers: [NotificationService],
  templateUrl: './event-section-rsvp.component.html',
  styleUrl: './event-section-rsvp.component.scss',
})
export class EventSectionRsvpComponent {
  @Input() loggedUser?: User;

  event?: Event;

  form?: FormGroup;

  constructor(
    private eventStore: EventStore,
    private eventService: EventService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private translateService: TranslateService,
  ) {
    this.eventStore.event.pipe(takeUntilDestroyed()).subscribe((event) => {
      if (event) {
        this.event = event;

        this.form = this.fb.group({
          plusOnes: this.fb.array([]),
        });

        if (!this.loggedUser || !this.loggedUser.id) {
          this.form.addControl('firstName', this.fb.control(''));
          this.form.addControl('lastName', this.fb.control(''));
          this.form.addControl('email', this.fb.control(''));
          this.form.addControl('phone', this.fb.control(''));
        }

        if (this.event.eventTemplate?.attendeeQuestions) {
          for (const question of this.event.eventTemplate?.attendeeQuestions) {
            this.form.addControl(
              `question#${question.id}`,
              this.fb.control(''),
            );
          }
        }
      }
    });
  }

  addPlusOne() {
    if (!this.form || !this.event) {
      return;
    }

    const plusOnes = this.form.controls['plusOnes'] as FormArray;

    if (plusOnes) {
      const plusOneFormGroup: FormGroup = this.fb.group({
        firstName: [''],
        lastName: [''],
        email: [''],
        phone: [''],
      });

      if (this.event.eventTemplate?.attendeeQuestions) {
        for (const question of this.event.eventTemplate?.attendeeQuestions) {
          plusOneFormGroup.addControl(
            `question#${question.id}`,
            this.fb.control(''),
          );
        }
      }

      setTimeout(() => {
        plusOnes.push(plusOneFormGroup);
      });
    }
  }

  removePlusOne(i: number) {
    const plusOnes = this.plusOnes;
    plusOnes.removeAt(i);
  }

  rsvp() {
    if (!this.form || !this.event) {
      return;
    }

    if (!this.form.valid) {
      return;
    }

    const value = this.form.value;

    const req: EventRSVPRequest = {
      rsvpType: RSVPOptionType.YES,
      numAttendees: 0,
    };

    if (!this.loggedUser || !this.loggedUser.id) {
      if (this.event.eventTemplate?.rsvpSettings?.showPlusOnesFirstName) {
        req.firstName = value.firstName;
      }
      if (this.event.eventTemplate?.rsvpSettings?.showPlusOnesLastName) {
        req.lastName = value.lastName;
      }
      if (this.event.eventTemplate?.rsvpSettings?.showPlusOnesEmail) {
        req.email = value.email;
      }
      if (this.event.eventTemplate?.rsvpSettings?.showPlusOnesPhone) {
        req.phone = value.phone;
      }
    }

    if (this.event.eventTemplate?.attendeeQuestions) {
      const answers: RSVPQuestionAnswer[] = [];
      for (const question of this.event.eventTemplate?.attendeeQuestions) {
        const answer: RSVPQuestionAnswer = {
          eventAttendeeQuestionId: question.id,
        };

        if (question.type === QuestionType.DROPDOWN) {
          answer.eventAttendeeQuestionOptionId =
            +value[`question#${question.id}`];
        } else if (
          question.type === QuestionType.TEXT ||
          question.type === QuestionType.TEXTAREA
        ) {
          answer.openAnswer = value[`question#${question.id}`];
        } else if (question.type === QuestionType.CHECKBOX) {
          answer.boolAnswer = !!value[`question#${question.id}`];
        } else if (question.type === QuestionType.NUMBER) {
          answer.openAnswer = value[`question#${question.id}`].toString();
        }

        answers.push(answer);
      }

      req.rsvpQuestionAnswers = answers;
    }

    if (value.plusOnes) {
      const groupMembers: RSVPGroupMemberData[] = [];
      for (const plusOne of value.plusOnes) {
        const groupMember: RSVPGroupMemberData = {};
        if (this.event.eventTemplate?.rsvpSettings?.showPlusOnesFirstName) {
          groupMember.firstName = plusOne.firstName;
        }
        if (this.event.eventTemplate?.rsvpSettings?.showPlusOnesLastName) {
          groupMember.lastName = plusOne.lastName;
        }
        if (this.event.eventTemplate?.rsvpSettings?.showPlusOnesEmail) {
          groupMember.email = plusOne.email;
        }
        if (this.event.eventTemplate?.rsvpSettings?.showPlusOnesPhone) {
          groupMember.phone = plusOne.phone;
        }

        if (this.event.eventTemplate?.attendeeQuestions) {
          const answers: RSVPQuestionAnswer[] = [];
          for (const question of this.event.eventTemplate?.attendeeQuestions) {
            const answer: RSVPQuestionAnswer = {
              eventAttendeeQuestionId: question.id,
            };

            if (question.type === QuestionType.DROPDOWN) {
              answer.eventAttendeeQuestionOptionId =
                +plusOne[`question#${question.id}`];
            } else if (
              question.type === QuestionType.TEXT ||
              question.type === QuestionType.TEXTAREA
            ) {
              answer.openAnswer = plusOne[`question#${question.id}`];
            } else if (question.type === QuestionType.CHECKBOX) {
              answer.boolAnswer = !!plusOne[`question#${question.id}`];
            } else if (question.type === QuestionType.NUMBER) {
              answer.openAnswer = plusOne[`question#${question.id}`].toString();
            }

            answers.push(answer);
          }

          groupMember.rsvpQuestionAnswers = answers;
        }

        groupMembers.push(groupMember);
      }

      req.groupMembers = groupMembers;

      req.numAttendees = groupMembers.length;
    }

    this.eventService.saveRSVP(this.event.id, req).subscribe({
      next: () => {
        if (this.event) {
          this.eventStore.refreshEvent(this.event.uri);

          this.dialog.open(EventAfterAttendOverlayDialogComponent, {
            width: '100%',
            height: '100%',
            maxWidth: '100%',
            panelClass: 'overlay-page-dialog',
            data: {
              event: this.event,
              rsvpType: RSVPOptionType.YES,
            },
          });
        }
      },
      error: (err) => {
        this.checkAndShowRSVPError(err);
      },
    });
  }

  getFormGroup(control: AbstractControl): FormGroup {
    return control as FormGroup;
  }

  get plusOnes(): FormArray {
    return this.form!.get('plusOnes') as FormArray;
  }

  private checkAndShowRSVPError(err: any) {
    if (err?.error === 'bad request - max capacity exceeded') {
      this.notificationService.error(
        this.translateService.instant('APP.EVENT_RSVP.ERRORS.MAX_CAPACITY'),
        this.translateService.instant('APP.EVENT_RSVP.ERRORS.COULDNT_RSVP'),
      );
    } else if (err?.error === 'bad request - rsvp disabled') {
      this.notificationService.error(
        this.translateService.instant('APP.EVENT_RSVP.ERRORS.DISABLED'),
        this.translateService.instant('APP.EVENT_RSVP.ERRORS.COULDNT_RSVP'),
      );
    } else if (err?.error === 'bad request - plus ones limit exceeded') {
      this.notificationService.error(
        this.translateService.instant('APP.EVENT_RSVP.ERRORS.PLUS_ONES_LIMIT'),
        this.translateService.instant('APP.EVENT_RSVP.ERRORS.COULDNT_RSVP'),
      );
    } else {
      this.notificationService.error(
        this.translateService.instant('APP.EVENT_RSVP.ERRORS.COULDNT_RSVP'),
      );
    }
  }

  get isLoggedUserAttendee(): boolean {
    return (
      !!this.loggedUser &&
      !!this.event?.getUserAttendeeRSVPOption(this.loggedUser.id) &&
      this.event.isNonPendingAttendee(this.loggedUser.id)
    );
  }
}
