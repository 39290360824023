import { NgOptimizedImage } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';
import { EventWishlistItem } from '@models/events/event-wishlist-item.model';

@Component({
  selector: 'app-event-section-wishlist-item',
  standalone: true,
  imports: [NgOptimizedImage],
  templateUrl: './event-section-wishlist-item.component.html',
  styleUrl: './event-section-wishlist-item.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class EventSectionWishlistItemComponent {
  @Input() item?: EventWishlistItem;

  navigateTo(url?: string) {
    if (url) {
      window.open(url, '_blank');
    }
  }
}
