import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { EventTemplateFont } from '@models/design-templates/event-template-font.model';
import { EventTemplateVisual } from '@models/design-templates/event-template-visual.model';
import { ConfigurableSectionItem } from '@models/events/dto/configurable-section-item.model';
import { EventFaq } from '@models/events/event-faq.model';
import { EventSectionType } from '@models/events/event-section-type.enum';
import { EventSection } from '@models/events/event-section.model';
import { EventSponsor } from '@models/events/event-sponsor.model';
import { EventTestimonial } from '@models/events/event-testimonial.model';
import { EventTimetableItem } from '@models/events/event-timetable-item.model';
import { Event } from '@models/events/event.model';
import { ImagePosition } from '@models/events/image-position.enum';
import { User } from '@models/users/user.model';
import { AdvancedOneColumnConfigureSectionComponent } from '@modules/events/components/advanced-one-column-configure-section/advanced-one-column-configure-section.component';
import { AdvancedOneColumnEventSectionComponent } from '@modules/events/components/advanced-one-column-event-section/advanced-one-column-event-section.component';
import { GetTicketsDialog } from '@modules/events/dialogs/get-tickets/get-tickets.dialog';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService } from '@services/auth/auth.service';
import { FontService } from '@services/shared/font.service';
import { ScreenWidthService } from '@services/shared/screen-width.service';
import { SidebarService } from '@services/sidebar.service';
import { EventStore } from '@services/stores/event.store';
import { Helpers } from '@utils/helpers';

@Component({
  selector: 'app-advanced-one-column-event',
  standalone: true,
  imports: [
    CommonModule,
    AdvancedOneColumnEventSectionComponent,
    TranslateModule,
    AdvancedOneColumnConfigureSectionComponent,
  ],
  providers: [ScreenWidthService],
  templateUrl: './advanced-one-column-event.component.html',
  styleUrl: './advanced-one-column-event.component.scss',
})
export class AdvancedOneColumnEventComponent implements OnInit, OnChanges {
  event?: Event;
  lowestTicketPrice?: string;
  showPopup: boolean = false;
  isDesktop?: boolean;
  loggedUser?: User;

  selectedSection?: EventSection;
  selectedTimeTableItem?: EventTimetableItem;
  selectedTestimonial?: EventTestimonial;
  selectedFaq?: EventFaq;
  selectedSponsor?: EventSponsor;
  selectedDay?: Date;
  @Input() isEdit?: boolean = false;
  isSidebarOpen: boolean = false;
  configurableObject?: ConfigurableSectionItem;

  constructor(
    private fontService: FontService,
    private eventStore: EventStore,
    private screenWidthService: ScreenWidthService,
    private authService: AuthService,
    private dialog: MatDialog,
    private viewContainerRef: ViewContainerRef,
    protected sidebarService: SidebarService,
  ) {
    this.eventStore.event.pipe(takeUntilDestroyed()).subscribe((event) => {
      this.event = event;

      this.lowestTicketPrice = this.event?.getLowestTicketPriceDisplay();

      if (this.font) {
        const primaryLogoFont = this.font.primaryLogoFont;
        const bodyFont = this.font.bodyFont;
        const fontFamilies: string[] = [];
        if (primaryLogoFont) {
          fontFamilies.push(primaryLogoFont);
        }
        if (bodyFont) {
          fontFamilies.push(bodyFont);
        }

        this.fontService.updateFonts(fontFamilies);

        Helpers.setEventFonts(
          this.font.primaryLogoFont,
          this.font.bodyFont,
          this.font.bodyFontWeight,
        );
      }
    });

    this.screenWidthService
      .isDesktop()
      .pipe(takeUntilDestroyed())
      .subscribe((isDesktop) => {
        this.isDesktop = isDesktop;
      });

    this.authService.userSubject
      .pipe(takeUntilDestroyed())
      .subscribe((user) => {
        this.loggedUser = user;
      });
  }

  onClose() {
    this.sidebarService.sidebarEventSectionDetailsStateSubject.next(false);
  }
  onConfigureSection(configurableObject: ConfigurableSectionItem) {
    if (!this.isSidebarOpen) {
      this.updateSelected(configurableObject);
      this.updateConfigurableObject();
      this.sidebarService.toggleEventSectionDetailsSidebarOpen();
    } else {
      this.updateSelected(configurableObject);
      this.updateConfigurableObject();
    }
  }

  updateSelected(configurableObject: ConfigurableSectionItem) {
    this.selectedSection = configurableObject.section;
    this.selectedTimeTableItem = configurableObject.timeTableItem;
    this.selectedFaq = configurableObject.faq;
    this.selectedSponsor = configurableObject.sponsor;
    this.selectedTestimonial = configurableObject.testimonial;
    this.selectedDay = configurableObject.day;
  }

  updateConfigurableObject() {
    this.configurableObject = {
      section: this.selectedSection,
      faq: this.selectedFaq,
      testimonial: this.selectedTestimonial,
      sponsor: this.selectedSponsor,
      timeTableItem: this.selectedTimeTableItem,
      day: this.selectedDay,
    };
  }
  getEventSectionByType(type: EventSectionType, imagePosition?: ImagePosition) {
    if (imagePosition) {
      return this.event?.eventTemplate?.getEventSectionByType(
        type,
        imagePosition,
      );
    }
    return this.event?.eventTemplate?.getEventSectionByType(type);
  }

  getEventSectionsOrdered() {
    return this.event?.eventTemplate?.getEventSectionsOrdered();
  }

  ngOnInit(): void {
    if (this.isDesktop) {
      setTimeout(() => {
        this.showPopup = true;
      }, 10000);
    } else {
      this.showPopup = true;
    }

    this.sidebarService.sidebarEventSectionDetailsState$.subscribe(
      (state: boolean) => {
        this.isSidebarOpen = state;
      },
    );
  }

  get visual(): EventTemplateVisual | undefined {
    return this.event?.eventTemplate?.visual;
  }

  openGetTicketsDialog() {
    const data = {
      event: this.event,
      mainImage: this.event?.getEncodedMainImage(true),
      loggedUser: this.loggedUser,
    };
    const dialogRef = this.dialog.open(GetTicketsDialog, {
      viewContainerRef: this.viewContainerRef,
      maxWidth: '100dvw',
      width: '100%',
      height: '100dvh',
      data: data,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  get isHostView(): boolean | undefined {
    return (
      (this.loggedUser &&
        this.loggedUser.id &&
        this.event?.isUserHost(this.loggedUser.id)) ||
      this.event?.isNotLoggedUserHost()
    );
  }
  get font(): EventTemplateFont | undefined {
    return this.event?.eventTemplate?.font;
  }

  protected readonly EventSectionType = EventSectionType;
  protected readonly ImagePosition = ImagePosition;

  ngOnChanges(changes: SimpleChanges): void {}
}
