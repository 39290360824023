<div class='timer-wrapper'>
  <div class='item'>
    <div class='value'>{{ months.toString().padStart(2, '0') }}</div>
    <div class='label'>{{ "APP.TIME_UNITS.MONTHS" | translate }}</div>
  </div>
  <div class='item'>
    <div class='value'>{{ days.toString().padStart(2, '0') }}</div>
    <div class='label'>{{ "APP.TIME_UNITS.DAYS" | translate }}</div>
  </div>
  <div class='item'>
    <div class='value'>{{ hours.toString().padStart(2, '0') }}</div>
    <div class='label'>{{ "APP.TIME_UNITS.HOURS" | translate }}</div>

  </div>
  <div class='item'>
    <div class='value'>{{ minutes.toString().padStart(2, '0') }}</div>
    <div class='label'>{{ "APP.TIME_UNITS.MINUTES" | translate }}</div>
  </div>
</div>
