@if (eventTimetable) {
  <div
    class="timetable border-top"
    [class.full-width]='isWeddingPage'
    [class.border-top-none]="
      (eventType !== EventType.SPORTPAGE &&
        eventType !== EventType.LANDINGPAGE) ||
      isUaEvent
    "
    [class.edit-mode]='isEdit'
    [class.wedding-page]='layout === EventTemplateLayout.ONE_COLUMN_ELEGANT || layout === EventTemplateLayout.ONE_COLUMN_MODERN'
  >
    @for (item of filteredItems; track item.id) {
      @if(isEdit){
        <div class='update-actions-menu'>
          <app-event-section-timetable-item
            [item]="item"
            [eventType]="eventType"
            [isUaEvent]="isUaEvent"
            [class.dashed-bordered]='isWeddingPage'
            [class.selected]='item === selectedItem'
            [event]='event'
            (click)='selectItem(item)'
          >
          </app-event-section-timetable-item>
          @if(isWeddingPage){
            @if(item === selectedItem){
              <div
                class='menu-trigger transparent'
                [ngStyle]='{
                "color": bodyColor
                }'
                [matMenuTriggerFor]="menu">
                <i class="far fa-ellipsis-v"></i>
              </div>
            }
          }@else{
            <div
              class='menu-trigger'
              [matMenuTriggerFor]="menu">
              <i class="far fa-ellipsis-v"></i>
            </div>
          }
          <mat-menu #menu="matMenu" class="table-actions-container">
            <button mat-menu-item (click)='onUpdate(UpdateActions.EDIT, item)'>
                <span class="menu-item-content">
                  <i class="far fa-pen"></i>
                  <span class="text">{{ 'APP.EDIT' | translate | camelCase }}</span>
                </span>
            </button>

            <button mat-menu-item (click)='onUpdate(UpdateActions.DELETE, item)'>
                <span class="menu-item-content">
                  <i class="far fa-trash"></i>
                  <span class="text"
                  >{{ 'APP.DELETE' |
                    translate | camelCase }}</span
                  >
                </span>
            </button>
          </mat-menu>
        </div>
      } @else {
        <app-event-section-timetable-item
          [item]="item"
          [eventType]="eventType"
          [isUaEvent]="isUaEvent"
          [event]='event'
        >
        </app-event-section-timetable-item>
      }

    }

    @if(layout === EventTemplateLayout.ONE_COLUMN_MODERN){
      <div class="line"></div>
    }
  </div>
}
