import { Component, Input } from '@angular/core';
import { EventTestimonial } from '@models/events/event-testimonial.model';
import { EventType } from '@models/events/event-type.enum';
import { Event } from '@models/events/event.model';

@Component({
  selector: 'app-event-section-testimonials-item',
  standalone: true,
  imports: [],
  templateUrl: './event-section-testimonials-item.component.html',
  styleUrl: './event-section-testimonials-item.component.scss',
})
export class EventSectionTestimonialsItemComponent {
  @Input() testimonial?: EventTestimonial;
  @Input() event?: Event;

  isGenericLayout() {
    return (
      this.event?.type === EventType.GENERIC_EVENT ||
      this.event?.type === EventType.GENERIC_LANDINGPAGE
    );
  }

  addQuotes() {
    return `&#x201F; ${this.testimonial?.description} &#x201F`;
  }

  getDescription() {
    if (this.isGenericLayout()) return this.testimonial?.description;
    else return this.addQuotes();
  }

  protected readonly EventType = EventType;
}
