import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
} from '@angular/material/dialog';
import { EventTemplateVisual } from '@models/design-templates/event-template-visual.model';
import { QuestionType } from '@models/event-attendance/event-attendee-question-type.enum';
import { EventAttendee } from '@models/event-attendance/event-attendee.model';
import { RSVPOptionType } from '@models/event-attendance/rsvp-option-type.enum';
import {
  EventRSVPRequest,
  RSVPQuestionAnswer,
} from '@models/events/dto/event-rsvp.request';
import { Event } from '@models/events/event.model';
import { User } from '@models/users/user.model';
import { EventRsvpQuestionComponent } from '@modules/events/components/event-rsvp-question/event-rsvp-question.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EventService } from '@services/events/event.service';
import { NotificationService } from '@services/shared/notification.service';
import { FormsModules } from '@utils/shared-modules';

@Component({
  selector: 'app-section-event-rsvp',
  standalone: true,
  imports: [
    CommonModule,
    FormsModules,
    TranslateModule,
    EventRsvpQuestionComponent,
    MatDialogContent,
    MatDialogActions,
  ],
  providers: [EventService, NotificationService],
  templateUrl: './section-event-rsvp.dialog.html',
  styleUrl: './section-event-rsvp.dialog.scss',
})
export class SectionEventRsvpDialog {
  form?: FormGroup;
  event?: Event;

  loggedUser?: User;

  invitedAttendeeUuid?: string;
  invitedAttendee?: EventAttendee;

  inviteeEmail?: string;
  inviteeFirstName?: string;
  inviteeLastName?: string;

  constructor(
    public dialogRef: MatDialogRef<SectionEventRsvpDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private eventService: EventService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
  ) {
    if (data) {
      this.event = data.event;

      this.loggedUser = data.loggedUser;
      this.invitedAttendeeUuid = data.invitedAttendeeUuid;
      this.invitedAttendee = data.invitedAttendee;

      this.inviteeEmail = data.inviteeEmail;
      this.inviteeFirstName = data.inviteeFirstName;
      this.inviteeLastName = data.inviteeLastName;

      let firstName = '';
      let lastName = '';
      let email = '';
      if (this.loggedUser && this.loggedUser.id) {
        firstName = this.loggedUser.getFirstName();
        lastName = this.loggedUser.getLastName();
        email = this.loggedUser.email;
      } else if (this.invitedAttendeeUuid && this.invitedAttendee) {
        firstName = this.invitedAttendee.getFirstName();
        lastName = this.invitedAttendee.getLastName();
        email = this.invitedAttendee.getEmail();
      } else if (this.inviteeEmail) {
        email = this.inviteeEmail;
        firstName = this.inviteeFirstName || '';
        lastName = this.inviteeLastName || '';
      }

      this.form = this.fb.group({
        firstName: [firstName, Validators.required],
        lastName: [lastName, Validators.required],
        email: [email, [Validators.required, Validators.email]],
      });

      if (this.event?.eventTemplate?.attendeeQuestions) {
        for (const question of this.event.eventTemplate.attendeeQuestions) {
          this.form.addControl(
            `question#${question.id}`,
            this.fb.control('', question.isRequired ? Validators.required : []),
          );
        }
      }
    }
  }

  rsvp(rsvpType: RSVPOptionType) {
    if (!this.event || !this.form || this.event.isInThePast()) {
      return;
    }

    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    const req: EventRSVPRequest = {
      rsvpType: rsvpType,
      numAttendees: 0,
    };

    if (!this.loggedUser || !this.loggedUser.id) {
      if (this.invitedAttendeeUuid) {
        req.attendeeUuid = this.invitedAttendeeUuid;
      } else if (this.inviteeEmail) {
        req.email = this.inviteeEmail;
        req.firstName = this.inviteeFirstName;
        req.lastName = this.inviteeLastName;
      }
    }

    const value = this.form.value;

    if (value.firstName !== '') {
      req.firstName = value.firstName;
    }
    if (value.lastName !== '') {
      req.lastName = value.lastName;
    }
    if (value.email !== '') {
      req.email = value.email;
    }

    if (this.event.eventTemplate?.attendeeQuestions) {
      const answers: RSVPQuestionAnswer[] = [];
      for (const question of this.event.eventTemplate?.attendeeQuestions) {
        if (value[`question#${question.id}`]) {
          const answer: RSVPQuestionAnswer = {
            eventAttendeeQuestionId: question.id,
          };

          if (question.type === QuestionType.DROPDOWN) {
            answer.eventAttendeeQuestionOptionId =
              +value[`question#${question.id}`];
          } else if (
            question.type === QuestionType.TEXT ||
            question.type === QuestionType.TEXTAREA
          ) {
            answer.openAnswer = value[`question#${question.id}`];
          } else if (question.type === QuestionType.CHECKBOX) {
            answer.boolAnswer = !!value[`question#${question.id}`];
          } else if (question.type === QuestionType.NUMBER) {
            answer.openAnswer = value[`question#${question.id}`].toString();
          }

          answers.push(answer);
        }
      }

      req.rsvpQuestionAnswers = answers;
    }

    this.eventService.saveRSVP(this.event.id, req).subscribe({
      next: () => {
        this.dialogRef.close({
          refresh: true,
          openEventAfterAttendOverlayDialog: true,
          rsvpType: req.rsvpType,
          attendeeData: req,
        });
      },
      error: (err) => {
        this.checkAndShowRSVPError(err);
      },
    });
  }

  close(event?: any) {
    this.dialogRef.close(event);
  }

  private checkAndShowRSVPError(err: any) {
    if (err?.error === 'bad request - max capacity exceeded') {
      this.notificationService.error(
        this.translateService.instant('APP.EVENT_RSVP.ERRORS.MAX_CAPACITY'),
        this.translateService.instant('APP.EVENT_RSVP.ERRORS.COULDNT_RSVP'),
      );
    } else if (err?.error === 'bad request - rsvp disabled') {
      this.notificationService.error(
        this.translateService.instant('APP.EVENT_RSVP.ERRORS.DISABLED'),
        this.translateService.instant('APP.EVENT_RSVP.ERRORS.COULDNT_RSVP'),
      );
    } else if (err?.error === 'bad request - plus ones limit exceeded') {
      this.notificationService.error(
        this.translateService.instant('APP.EVENT_RSVP.ERRORS.PLUS_ONES_LIMIT'),
        this.translateService.instant('APP.EVENT_RSVP.ERRORS.COULDNT_RSVP'),
      );
    } else if (err?.error === 'bad request - already part of guest list') {
      this.notificationService.error(
        this.translateService.instant('APP.EVENT_RSVP.ERRORS.GUEST_EXISTS'),
        this.translateService.instant(
          'APP.EVENT_RSVP.ERRORS.RSVP_NOT_POSSIBLE',
        ),
      );
    } else {
      this.notificationService.error(
        this.translateService.instant('APP.EVENT_RSVP.ERRORS.COULDNT_RSVP'),
      );
    }
  }

  get visual(): EventTemplateVisual | undefined {
    return this.event?.eventTemplate?.visual;
  }

  protected readonly RSVPOptionType = RSVPOptionType;
}
