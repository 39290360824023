@if (events) {
  @if (isDesktop || isGeneric) {
    <div class="wrapper" [class.ua-event]="isUaEvent" [class.generic]='isGeneric'>
    @for (item of events; track item.id) {
        <app-event-section-event-list-item [event]="item" />
    }
    </div>
  } @else {
    <div class="mobile-wrapper">
      @for (item of filteredEvents; track item.id) {
        <div class="wrapper" [class.ua-event]="isUaEvent">
          <app-event-section-event-list-item [event]="item" />
        </div>
      }
      @if (events.length > 3) {
        @if (!isShowMore) {
          <button class="btn btn-show-more" (click)="onShowMore()">
            {{ "APP.SPORT_PAGE.SHOW_MORE" | translate }}
          </button>
        } @else {
          <button class="btn btn-show-more" (click)="onShowLess()">
            {{ "APP.SPORT_PAGE.SHOW_LESS" | translate }}
          </button>
        }
      }
    </div>
  }
}
