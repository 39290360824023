@if (item) {
  @if (
    eventType === EventType.SPORTPAGE || eventType === EventType.LANDINGPAGE
  ) {
    <div class="sport-timeline-item" [class.ua-section]="isUaEvent">
      <!--
      <div class="timetable-time">{{ item.timeFrom }} - {{ item.timeTo }} {{ 'APP.TIME_AGO.SINGLE_HOUR' | translate }}</div>
-->
      <div class="ua-timeline-title">
        @if (isUaEvent) {
          <object
            data="https://static.eventpage.ai/event-templates/event-templates-1594/bullet.svg"
            type="image/svg+xml"
          ></object>
        }
        <div class="timetable-title" [innerText]="item.title"></div>
      </div>
      <div class="timetable-description" [innerText]="item.description"></div>
    </div>
  } @else if(layout === EventTemplateLayout.ONE_COLUMN_ELEGANT || layout === EventTemplateLayout.ONE_COLUMN_MODERN){
    <div class="timetable-item two-columns" [class.modern]='layout === EventTemplateLayout.ONE_COLUMN_MODERN'>
      @if(layout === EventTemplateLayout.ONE_COLUMN_MODERN){
        <i class="fas fa-circle dot"></i>
      }
      <div class="timetable-time">
        <div class='hour'>{{ item.timeFrom.split(":")[0]}}
          <div class='minutes'>
            {{ item.timeFrom.split(":")[1]}}
          </div></div>
      </div>
      <div class='timetable-info'>
        <div class="timetable-title" [innerText]="item.title"></div>
        <div class="timetable-description" [innerText]="item.description"></div>
      </div>

    </div>
  }@else {
    <div class="timetable-item">
      <div class="timetable-time">{{ item.timeFrom }}
        @if(item.timeTo){
          - {{ item.timeTo }}
        }
     </div>
      <div class="timetable-title" [innerText]="item.title"></div>
    </div>
  }
}
