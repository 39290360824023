@if (event && visual && font && sections) {
  <div class="page" [class.host-view]="isHostView" [class.ua-page]="isUaEvent">
    <div class="wrapper main">
      <div
        class="left-sticky"
        [style.backgroundColor]="
          visual.backgroundColor ? visual.backgroundColor : ''
        "
      >
        <div
          class="overlay"
          [ngStyle]="{
            'background-image': 'url(' + event.getEncodedMainImage() + ')',
            'background-color': visual.backgroundColor
          }"
        >
          @if (event.isMainPictureVideo()) {
            <video
              #keyVisualOverlayVideo
              class="overlay-video"
              [muted]="'muted'"
              autoplay
              playsinline
              loop
              disablePictureInPicture
            >
              <source [src]="event.mainPictureUrl" type="video/mp4" />
            </video>
          }
        </div>
        @if (event.isMainPictureVideo()) {
          <div
            class="key-visual-video-cont"
            [class.attendee-view]="isAttendeeView || isPendingAttendeeView"
          >
            <video
              #keyVisualVideo
              class="key-visual-video"
              [muted]="'muted'"
              autoplay
              playsinline
              loop
              disablePictureInPicture
            >
              <source [src]="event.mainPictureUrl" type="video/mp4" />
            </video>

            @if (isVideoMuted) {
              <button
                class="unmute-button"
                #unMuteTooltip="matTooltip"
                matTooltip="{{ 'APP.UNMUTE_VIDEO_TOOLTIP' | translate }}"
                matTooltipPosition="above"
                matTooltipTouchGestures="off"
                [matTooltipClass]="['unmute-tooltip']"
                (click)="onUnmuteVideo()"
              >
                <i class="fa fa-volume-slash"></i>
              </button>
            } @else {
              <button class="unmute-button" (click)="onMuteVideo()">
                <i class="fa fa-volume"></i>
              </button>
            }
          </div>
        } @else {
          <img
            [src]="event.getEncodedMainImage()"
            class="key-visual-img"
            alt="Key Visual"
          />
        }
      </div>
      <div class="right-content main-section">
        @if (event.type === EventType.LANDINGPAGE) {
          <app-event-section
            [section]="getEventSectionByType(EventSectionType.MAIN)"
            [loggedUser]="loggedUser"
            (findEvent)="scrollToTourDates()"
            (learnMore)="scrollToProgram()"
          ></app-event-section>
          @if (getEventSectionByType(EventSectionType.TIMELINE) && isDesktop) {
            @if (isUaEvent) {
              <div class="custom-border">
                <img
                  src="https://static.eventpage.ai/event-templates/event-templates-1594/graphic-increased-running.png"
                  class="border-image"
                />
              </div>
            }
            <app-event-section
              [section]="getEventSectionByType(EventSectionType.TIMELINE)"
              [loggedUser]="loggedUser"
            ></app-event-section>
          }
        } @else if (event.type === EventType.SPORTPAGE) {
          <app-event-section
            [section]="getEventSectionByType(EventSectionType.MAIN)"
            [loggedUser]="loggedUser"
          ></app-event-section>
        }
      </div>
    </div>

    @if (
      getEventSectionByType(EventSectionType.TIMELINE) &&
      !isDesktop &&
      this.event.type === EventType.LANDINGPAGE
    ) {
      <div class="wrapper-full program-section">
        @if (isUaEvent) {
          <div class="custom-border">
            <img
              src="https://static.eventpage.ai/event-templates/event-templates-1594/graphic-increased-running.png"
              class="border-image"
            />
          </div>
        }
        <app-event-section
          [section]="getEventSectionByType(EventSectionType.TIMELINE)"
          [loggedUser]="loggedUser"
        ></app-event-section>
      </div>
    }

    <!--Maps and location-->
    @if (
      event.type === EventType.SPORTPAGE &&
      getEventSectionByType(EventSectionType.DATE_LOCATION)
    ) {
      <div class="wrapper wrapper-reverse">
        <div class="left-sticky map-section">
          <app-event-section
            style="width: 100%; height: 100%"
            [section]="getEventSectionByType(EventSectionType.LOCATION)"
            [loggedUser]="loggedUser"
          ></app-event-section>
        </div>
        <div class="right-content location-section">
          @if (isUaEvent) {
            <div class="custom-border">
              <img
                src="https://static.eventpage.ai/event-templates/event-templates-1594/graphic-increased-running.png"
                class="border-image"
              />
            </div>
          }
          <app-event-section
            [section]="getEventSectionByType(EventSectionType.DATE_LOCATION)"
            [loggedUser]="loggedUser"
          ></app-event-section>
          @if (isUaEvent && !aboutHostsSection && isDesktop) {
            <ng-container *ngTemplateOutlet="poweredByFooter"></ng-container>
          }
        </div>
      </div>
    }

    <!--Sportpage Sponsors-->
    @if (
      event.type === EventType.SPORTPAGE &&
      getEventSectionByType(EventSectionType.SPONSORS)
    ) {
      <div class="wrapper-full sponsors-section">
        <app-event-section
          [section]="getEventSectionByType(EventSectionType.SPONSORS)"
          [loggedUser]="loggedUser"
        ></app-event-section>
      </div>
    }

    <!--Program-->
    @if (
      event.type === EventType.SPORTPAGE &&
      getEventSectionByType(EventSectionType.TIMELINE)
    ) {
      <div class="wrapper">
        @if (isDesktop) {
          <div
            class="left-sticky"
            [style.backgroundColor]="
              visual.backgroundColor ? visual.backgroundColor : ''
            "
          >
            <div
              class="overlay"
              [ngStyle]="{
                'background-image': 'url(' + event.getEncodedMainImage() + ')',
                'background-color': visual.backgroundColor
              }"
            >
              @if (event.isMainPictureVideo()) {
                <video
                  #keyVisualOverlayVideo
                  class="overlay-video"
                  [muted]="'muted'"
                  autoplay
                  playsinline
                  loop
                  disablePictureInPicture
                >
                  <source [src]="event.mainPictureUrl" type="video/mp4" />
                </video>
              }
            </div>
            @if (event.isMainPictureVideo()) {
              <div
                class="key-visual-video-cont"
                [class.attendee-view]="isAttendeeView || isPendingAttendeeView"
              >
                <video
                  #keyVisualVideo
                  class="key-visual-video"
                  [muted]="'muted'"
                  autoplay
                  playsinline
                  loop
                  disablePictureInPicture
                >
                  <source [src]="event.mainPictureUrl" type="video/mp4" />
                </video>

                @if (isVideoMuted) {
                  <button
                    class="unmute-button"
                    #unMuteTooltip="matTooltip"
                    matTooltip="{{ 'APP.UNMUTE_VIDEO_TOOLTIP' | translate }}"
                    matTooltipPosition="above"
                    matTooltipTouchGestures="off"
                    [matTooltipClass]="['unmute-tooltip']"
                    (click)="onUnmuteVideo()"
                  >
                    <i class="fa fa-volume-slash"></i>
                  </button>
                } @else {
                  <button class="unmute-button" (click)="onMuteVideo()">
                    <i class="fa fa-volume"></i>
                  </button>
                }
              </div>
            } @else {
              <img
                [src]="event.getEncodedMainImage()"
                class="key-visual-img"
                alt="Key Visual"
              />
            }
          </div>
        }
        <div class="right-content program-section">
          @if (getEventSectionByType(EventSectionType.TIMELINE)) {
            <app-event-section
              [section]="getEventSectionByType(EventSectionType.TIMELINE)"
              [loggedUser]="loggedUser"
            ></app-event-section>
          }
        </div>
      </div>
    }

    <!--Landingpage Sponsors-->
    @if (
      event.type === EventType.LANDINGPAGE &&
      getEventSectionByType(EventSectionType.SPONSORS)
    ) {
      <div class="wrapper-full sponsors-section">
        <app-event-section
          [section]="getEventSectionByType(EventSectionType.SPONSORS)"
          [loggedUser]="loggedUser"
        ></app-event-section>
      </div>
    }

    <!--Tour Dates-->
    @if (event.type === EventType.LANDINGPAGE) {
      <div class="wrapper">
        @if (isDesktop) {
          <div class="left-sticky map-section">
            <app-event-section
              style="width: 100%; height: 100%"
              [section]="getEventSectionByType(EventSectionType.LOCATION)"
              [loggedUser]="loggedUser"
            ></app-event-section>
          </div>
        }
        <div class="right-content" id="event-list-section">
          @if (getEventSectionByType(EventSectionType.EVENT_LIST)) {
            @if (isUaEvent) {
              <div class="custom-border"></div>
            }
            <app-event-section
              [section]="getEventSectionByType(EventSectionType.EVENT_LIST)"
              [loggedUser]="loggedUser"
            ></app-event-section>
          }
        </div>
      </div>
    }

    <!--Testimonials-->
    @if (getEventSectionByType(EventSectionType.TESTIMONIALS)) {
      <div class="wrapper-full testimonials-section">
        <app-event-section
          [section]="getEventSectionByType(EventSectionType.TESTIMONIALS)"
          [loggedUser]="loggedUser"
        ></app-event-section>
      </div>
    }

    <ng-template #poweredByFooter>
      @if (event.uri.includes("mizuno")) {
        <div class="powered-by">
          <div class="footer-powered-by-cont">
            <span>{{ "APP.POWERED_BY" | translate }}</span>
            <a class="footer-icon-a" routerLink="/">
              <img
                class="media-object footer-icon"
                [src]="userTheme === 'dark' ? env.iconDarkAsset : env.iconAsset"
                alt="weddingpage.ai - Logo"
              />
            </a>
          </div>
          <span class="location-info">
            Das Event wird veranstaltet durch die B2M Creative GmbH <br />
            Lycheneer Str. 51, 10437 Berlin<br /><br />Hier geht's zu den
            <a
              [routerLink]="['/mizuno-terms-of-use']"
              target="_blank"
              class="link"
            >
              Teilnahmebedingungen.
            </a>
          </span>
          <div class="widget-item">
            <a href="https://eventpage.ai/imprint" target="_blank">{{
              "APP.IMPRINT" | translate
            }}</a>
          </div>
        </div>
      } @else if (event.uri.includes("underarmour-infinite-elite-testival")) {
        <div class="powered-by">
          <div class="footer-powered-by-cont">
            <span>{{ "APP.POWERED_BY" | translate }}</span>
            <a class="footer-icon-a" routerLink="/">
              <img
                class="media-object footer-icon"
                [src]="userTheme === 'dark' ? env.iconDarkAsset : env.iconAsset"
                alt="weddingpage.ai - Logo"
              />
            </a>
          </div>
          <span class="location-info">
            Das Event wird veranstaltet durch die <br />
            B2M Creative GmbH Franz-Joseph-Straße 1, 80801 München<br /><br />Hier
            geht's zu den
            <a
              [routerLink]="['/underarmour-terms-of-use']"
              target="_blank"
              class="link"
            >
              Teilnahmebedingungen.
            </a>
          </span>
          <div class="widget-item">
            <a href="https://eventpage.ai/imprint" target="_blank">{{
              "APP.IMPRINT" | translate
            }}</a>
          </div>
        </div>
      }
    </ng-template>

    <!--About Hosts & FAQ-->
    @if (aboutHostsSection) {
      <div class="wrapper wrapper-reverse">
        <div
          class="left-sticky host-section"
          [style.backgroundColor]="
            visual.backgroundColor ? visual.backgroundColor : ''
          "
        >
          <div
            class="overlay host-image"
            [ngStyle]="{
              'background-image':
                'url(' + aboutHostsSection.backgroundImageUrl + ')',
              'background-color': visual.backgroundColor
            }"
          >
            @if (aboutHostsSection.isSectionBgVideo()) {
              <video
                class="overlay-video"
                [muted]="'muted'"
                autoplay
                playsinline
                loop
                disablePictureInPicture
              >
                <source
                  [src]="aboutHostsSection.backgroundImageUrl"
                  type="video/mp4"
                />
              </video>
            }
          </div>
          @if (aboutHostsSection.isSectionBgVideo()) {
            <div
              class="key-visual-video-cont"
              [class.attendee-view]="isAttendeeView || isPendingAttendeeView"
            >
              <video
                #aboutHostsSectionVideo
                class="key-visual-video"
                [muted]="'muted'"
                autoplay
                playsinline
                loop
                disablePictureInPicture
              >
                <source
                  [src]="aboutHostsSection.backgroundImageUrl"
                  type="video/mp4"
                />
              </video>

              @if (isVideoMuted) {
                <button
                  class="unmute-button"
                  #unMuteTooltip="matTooltip"
                  matTooltip="{{ 'APP.UNMUTE_VIDEO_TOOLTIP' | translate }}"
                  matTooltipPosition="above"
                  matTooltipTouchGestures="off"
                  [matTooltipClass]="['unmute-tooltip']"
                  (click)="onUnmuteAboutHostsSectionVideo()"
                >
                  <i class="fa fa-volume-slash"></i>
                </button>
              } @else {
                <button
                  class="unmute-button"
                  (click)="onMuteAboutHostsSectionVideo()"
                >
                  <i class="fa fa-volume"></i>
                </button>
              }
            </div>
          } @else {
            <img
              [src]="getSectionUrl(aboutHostsSection)"
              class="key-visual-img"
              alt="Key Visual"
            />
          }
        </div>
        <div class="right-content host-section">
          @if (isUaEvent) {
            <div class="custom-border pb-border"></div>
          }
          <app-event-section
            [section]="aboutHostsSection"
            [loggedUser]="loggedUser"
          ></app-event-section>
          @if (isUaEvent) {
            <div class="custom-border pt-border"></div>
          }
          @if (isDesktop) {
            <app-event-section
              [section]="getEventSectionByType(EventSectionType.FAQ)"
              [loggedUser]="loggedUser"
            ></app-event-section>
            <ng-container *ngTemplateOutlet="poweredByFooter"></ng-container>
          }
        </div>
      </div>

      @if (this.aboutHostsSection && !isDesktop) {
        <div class="wrapper-full faq-section">
          <app-event-section
            [section]="getEventSectionByType(EventSectionType.FAQ)"
            [loggedUser]="loggedUser"
          ></app-event-section>
        </div>
      }
    }
    @if (!isDesktop) {
      <div class="wrapper-full footer-section">
        @if (event.uri.includes("mizuno")) {
          <div class="powered-by mobile">
            <span class="location-info">
              Das Event wird veranstaltet durch die B2M Creative GmbH <br />
              Lycheneer Str. 51, 10437 Berlin<br /><br />Hier geht's zu den
              <a
                [routerLink]="['/mizuno-terms-of-use']"
                target="_blank"
                class="link"
              >
                Teilnahmebedingungen.
              </a>
            </span>
            <div class="bottom-section">
              <div class="footer-powered-by-cont">
                <span>{{ "APP.POWERED_BY" | translate }}</span>
                <a class="footer-icon-a" routerLink="/">
                  <img
                    class="media-object footer-icon"
                    [src]="
                      userTheme === 'dark' ? env.iconDarkAsset : env.iconAsset
                    "
                    alt="weddingpage.ai - Logo"
                  />
                </a>
              </div>
              <div class="widget-item">
                <a href="https://eventpage.ai/imprint" target="_blank">{{
                  "APP.IMPRINT" | translate
                }}</a>
              </div>
            </div>
          </div>
        } @else if (event.uri.includes("underarmour-infinite-elite-testival")) {
          <div class="powered-by mobile">
            <span class="location-info">
              Das Event wird veranstaltet durch die <br />
              B2M Creative GmbH Franz-Joseph-Straße 1, 80801 München<br /><br />Hier
              geht's zu den
              <a
                [routerLink]="['/underarmour-terms-of-use']"
                target="_blank"
                class="link"
              >
                Teilnahmebedingungen.
              </a>
            </span>
            <div class="bottom-section">
              <div class="footer-powered-by-cont">
                <span>{{ "APP.POWERED_BY" | translate }}</span>
                <a class="footer-icon-a" routerLink="/">
                  <img
                    class="media-object footer-icon"
                    [src]="
                      userTheme === 'dark' ? env.iconDarkAsset : env.iconAsset
                    "
                    alt="weddingpage.ai - Logo"
                  />
                </a>
              </div>
              <div class="widget-item">
                <a href="https://eventpage.ai/imprint" target="_blank">{{
                  "APP.IMPRINT" | translate
                }}</a>
              </div>
            </div>
          </div>
        }
      </div>
    }
  </div>
}
