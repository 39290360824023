import {
  isPlatformServer,
  NgStyle,
  NgTemplateOutlet,
  UpperCasePipe,
} from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { MatIconButton } from '@angular/material/button';
import {
  MatDialog,
  MatDialogRef,
  MatDialogState,
} from '@angular/material/dialog';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { EventTemplateLayout } from '@models/design-templates/event-template-layout.enum';
import { EventTemplateVisual } from '@models/design-templates/event-template-visual.model';
import { RSVPOptionType } from '@models/event-attendance/rsvp-option-type.enum';
import { ConfigurableSectionItem } from '@models/events/dto/configurable-section-item.model';
import { EventFaq } from '@models/events/event-faq.model';
import { EventSectionBackgroundSize } from '@models/events/event-section-background-size.enum';
import { EventSectionType } from '@models/events/event-section-type.enum';
import { EventSection } from '@models/events/event-section.model';
import { EventTimetable } from '@models/events/event-timetable.model';
import { EventType } from '@models/events/event-type.enum';
import { EventWeddingParty } from '@models/events/event-wedding-party.model';
import { Event } from '@models/events/event.model';
import { ImagePosition } from '@models/events/image-position.enum';
import { User } from '@models/users/user.model';
import { EventRsvpComponent } from '@modules/events/components/event-rsvp/event-rsvp.component';
import { EventSectionEventListComponent } from '@modules/events/components/event-section-event-list/event-section-event-list.component';
import { EventSectionGalleryComponent } from '@modules/events/components/event-section-gallery/event-section-gallery.component';
import { EventSectionLocationDateComponent } from '@modules/events/components/event-section-location-date/event-section-location-date.component';
import { EventSectionSponsorsComponent } from '@modules/events/components/event-section-sponsors/event-section-sponsors.component';
import { EventSectionTestimonialsComponent } from '@modules/events/components/event-section-testimonials/event-section-testimonials.component';
import { EventSectionWishlistComponent } from '@modules/events/components/event-section-wishlist/event-section-wishlist.component';
import { EventStartDateTimerComponent } from '@modules/events/components/event-start-date-timer/event-start-date-timer.component';
import { RichTextEditorComponent } from '@modules/shared/components/rich-text-editor/rich-text-editor.component';
import { AddressPickerDialog } from '@modules/shared/dialogs/address-picker/address-picker.dialog';
import { ImagePickerDialog } from '@modules/shared/dialogs/image-picker/image-picker.dialog';
import { TranslateModule } from '@ngx-translate/core';
import { EventService } from '@services/events/event.service';
import { ScreenWidthService } from '@services/shared/screen-width.service';
import { EventStore } from '@services/stores/event.store';
import { QuillUtils } from '@utils/quill-utils';
import { NgxColorsModule } from 'ngx-colors';
import { SafeHtmlPipe } from '../../../../pipes/safe-html.pipe';
import { EventSectionFaqComponent } from '../event-section-faq/event-section-faq.component';
import { EventSectionMapComponent } from '../event-section-map/event-section-map.component';
import { EventSectionRsvpComponent } from '../event-section-rsvp/event-section-rsvp.component';
import { EventSectionTimetableComponent } from '../event-section-timetable/event-section-timetable.component';
import { EventSectionWeddingPartyComponent } from '../event-section-wedding-party/event-section-wedding-party.component';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { HeartIconComponent } from '@modules/shared/components/heart-icon/heart-icon.component';
import { TextEditableDirective } from '../../../../directives/text-editable.directive';

@Component({
  selector: 'app-event-section',
  standalone: true,
  imports: [
    EventSectionTimetableComponent,
    EventSectionMapComponent,
    EventSectionWeddingPartyComponent,
    EventSectionRsvpComponent,
    EventSectionFaqComponent,
    EventSectionSponsorsComponent,
    EventSectionTestimonialsComponent,
    EventSectionEventListComponent,
    TranslateModule,
    UpperCasePipe,
    EventSectionLocationDateComponent,
    EventRsvpComponent,
    SafeHtmlPipe,
    RichTextEditorComponent,
    EventSectionWishlistComponent,
    EventSectionGalleryComponent,
    EventStartDateTimerComponent,
    NgTemplateOutlet,
    NgStyle,
    MatIconButton,
    MatMenu,
    MatMenuTrigger,
    MatMenuItem,
    NgxColorsModule,
    FormsModule,
    HeartIconComponent,
    TextEditableDirective,
  ],
  templateUrl: './event-section.component.html',
  styleUrl: './event-section.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class EventSectionComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() section?: EventSection;
  @Input() parentSection?: EventSection;
  @Input() isSubSection = false;
  @Input() loggedUser?: User;
  @Input() isEdit?: boolean = false;
  @Input() layout?: EventTemplateLayout;
  @Input() isHostView?: boolean = false;
  @Output() findEvent = new EventEmitter();
  @Output() learnMore = new EventEmitter();
  @Output() editSection = new EventEmitter<ConfigurableSectionItem>();
  @Output() updateEvent = new EventEmitter();
  @Output() changeDate = new EventEmitter();
  @Output() changeAddress = new EventEmitter();
  @Output() onRsvp = new EventEmitter();
  @Output() onRsvpChangeTitle = new EventEmitter();
  @Output() onPaste = new EventEmitter();
  @ViewChild('colorPickerTrigger', { read: ElementRef })
  colorPickerTrigger!: ElementRef;

  @ViewChild('backgroundVideo', { read: ElementRef, static: false })
  backgroundVideo?: ElementRef;

  isDesktop: boolean = false;
  isOver380: boolean = false;
  imagePickerDialogRef?: MatDialogRef<ImagePickerDialog>;

  dialogRef?: MatDialogRef<AddressPickerDialog>;

  locationsSafeHtml: SafeHtml | undefined;

  event?: Event;

  isServer = false;
  isDarkThemeDesign = false;
  isWeddingPage: boolean = false;
  isFadeUp: boolean = false;

  private saveTextSubject = new Subject<{ event: any; textType: string }>();
  private destroy$ = new Subject<void>();

  onFindEvent() {
    this.findEvent.emit();
  }
  onLearnMore() {
    this.learnMore.emit();
  }

  getAttrId(sectionType: EventSectionType) {
    let type;
    if (sectionType === EventSectionType.RSVP) {
      type = 'rsvp-section';
    } else if (
      sectionType === EventSectionType.TIMELINE &&
      this.event?.type === EventType.LANDINGPAGE
    ) {
      type = 'program-tour';
    } else {
      type = null;
    }
    return type;
  }

  constructor(
    private eventStore: EventStore,
    @Inject(PLATFORM_ID) platformId: Object,
    private sanitizer: DomSanitizer,
    private eventService: EventService,
    private dialog: MatDialog,
    private screenWidthService: ScreenWidthService,
    private cdr: ChangeDetectorRef,
  ) {
    this.isServer = isPlatformServer(platformId);

    this.screenWidthService
      .isDesktop()
      .pipe(takeUntilDestroyed())
      .subscribe((isDesktop) => (this.isDesktop = isDesktop));

    this.screenWidthService
      .isOver380()
      .pipe(takeUntilDestroyed())
      .subscribe((isOver380) => (this.isOver380 = isOver380));

    this.eventStore.event.pipe(takeUntilDestroyed()).subscribe((event) => {
      if (event) {
        this.event = event;
        this.isDarkThemeDesign =
          event.type === EventType.SPORTPAGE ||
          event.type === EventType.LANDINGPAGE;
        this.isWeddingPage = event.type === EventType.WEDDINGPAGE;
      }
    });
  }

  ngAfterViewInit(): void {
    this.checkBgVideoAutoplay();
  }

  get visual(): EventTemplateVisual | undefined {
    return this.event?.eventTemplate?.visual;
  }

  get visualBackgroundHevcUrl(): string | undefined {
    if (!this.visual?.background) {
      return undefined;
    }

    if (this.isDesktop || this.visual?.background?.h265MobileUrl === '') {
      return this.visual?.background?.h265Url;
    } else {
      return this.visual?.background?.h265MobileUrl;
    }
  }
  get visualBackgroundUrl(): string | undefined {
    if (!this.visual?.background) {
      return undefined;
    }

    if (this.isDesktop || this.visual?.background?.mobileUrl === '') {
      return this.visual?.background?.url;
    } else {
      return this.visual?.background?.mobileUrl;
    }
  }
  checkBgVideoAutoplay(): void {
    if (
      !this.backgroundVideo ||
      !this.backgroundVideo.nativeElement ||
      !this.backgroundVideo.nativeElement.play
    ) {
      return;
    }

    const promise = this.backgroundVideo.nativeElement.play();
    if (promise) {
      promise
        .then((_: any) => {})
        .catch((error: any) => {
          if (error.name === 'NotAllowedError') {
            this.backgroundVideo?.nativeElement.remove();
          }
        });
    }
  }

  ngOnInit(): void {
    if (
      !this.isServer &&
      (this.event?.eventTemplate?.layout ===
        EventTemplateLayout.ONE_COLUMN_MODERN ||
        this.event?.eventTemplate?.layout ===
          EventTemplateLayout.ONE_COLUMN_ELEGANT)
    ) {
      this.isFadeUp = true;
    }

    this.locationsSafeHtml = this.getLocationSafeHtml();
    this.saveTextSubject
      .pipe(debounceTime(250), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(({ event, textType }) => {
        this.debouncedSaveText(event, textType);
      });
  }

  onSectionActionTitleChange(value: string) {
    this.onRsvpChangeTitle.emit(value);
  }

  onPasteText(event: ClipboardEvent) {
    this.onPaste.emit(event);
  }

  onColorChange(color: string, property: string) {
    const request = {
      ...this.section,
      [property]: color,
    };
    this.updateSection(request);
  }

  openPalette() {
    const triggerElement = this.colorPickerTrigger.nativeElement;
    triggerElement.click();
  }

  openPickerDialog() {
    this.changeAddress.emit();
  }

  onChangeDate() {
    this.changeDate.emit();
  }

  getSectionColor(isHeadline?: boolean, isElegant?: boolean) {
    if (this.section && this.parentSection && this.parentSection.bodyColor) {
      return this.parentSection.bodyColor;
    } else if (
      this.section &&
      this.section.bodyColor &&
      this.section.bodyColor?.trim() !== ''
    ) {
      return this.section.bodyColor;
    } else if (
      isElegant &&
      isHeadline &&
      this.section &&
      (!this.section.bodyColor || this.section.bodyColor.trim() === '')
    ) {
      return this.section.type === EventSectionType.FAQ ||
        this.section.type === EventSectionType.TIMELINE
        ? 'var(--event-section-accent-body-color)'
        : 'var(--event-section-headline-color)';
    } else {
      if (this.isAccentSection) {
        return 'var(--event-section-accent-body-color)';
      } else {
        if (isHeadline) {
          return 'var(--event-section-headline-color)';
        } else {
          return 'var(--event-section-body-color)';
        }
      }
    }
  }

  openGoogleMaps() {
    if (!this.event) {
      return;
    }

    if (this.isEdit) {
      this.openPickerDialog();
    } else {
      window.open(this.event.getGoogleMapsLink(), '_blank');
    }
  }

  rsvp() {
    this.onRsvp.emit(RSVPOptionType.YES);
  }
  openImagePicker(isSection: boolean) {
    if (
      this.imagePickerDialogRef &&
      this.imagePickerDialogRef.getState() !== MatDialogState.CLOSED
    ) {
      return;
    }

    const dataObject = isSection
      ? {
          event: this.event,
          eventImagePicker: true,
          loggedUser: this.loggedUser,
          eventSection: this.section,
        }
      : {
          event: this.event,
          eventImagePicker: true,
          loggedUser: this.loggedUser,
        };
    this.imagePickerDialogRef = this.dialog.open(ImagePickerDialog, {
      maxWidth: '602px',
      maxHeight: '100vh',
      width: '100%',
      height: 'auto',
      data: dataObject,
      panelClass: ['normal-dialog'],
    });

    this.imagePickerDialogRef.afterClosed().subscribe((res) => {
      if (res) {
        if (res.event) {
          this.event = res.event;
        }
        if (this.event && res.imageUrl && res.imageUrl !== '') {
          if (!isSection) {
            this.event.mainPictureUrl = res.imageUrl;
          }
          this.eventStore.setEvent(this.event);
        }
      }
    });
  }

  onConfigureSection(configurableObject?: ConfigurableSectionItem) {
    if (configurableObject) {
      this.editSection.emit({
        ...configurableObject,
        section: this.section,
      });
    } else {
      this.editSection.emit({
        section: this.section,
      });
    }
  }
  debouncedSaveText(event: any, textType: string) {
    const value =
      textType === 'title' ? QuillUtils.replaceSpacesWithNbsp(event) : event;
    const request = {
      ...this.section,
      [textType]:
        value[textType] === '' || value === ''
          ? '<p></p>'
          : textType === 'title'
            ? value
            : value[textType],
    };
    this.updateSection(request);
  }

  saveText(event: any, textType: string) {
    this.saveTextSubject.next({ event, textType });
  }

  updateSection(section: any) {
    if (!this.event) return;

    this.eventService
      .updateEvent(this.event.id, { section: section })
      .subscribe((event) => {
        this.event = event;
        this.eventStore.refreshEvent(this.event.uri);
      });
  }
  getLocationSafeHtml(): SafeHtml {
    if (this.isUaSection) {
      const address = this.event
        ?.getSportPageLocationDisplay(true)
        ?.toUpperCase();
      const htmlString = `<object
            data="https://static.eventpage.ai/event-templates/event-templates-1594/bullet.svg"
            type="image/svg+xml"
          ></object> ${address}`;
      return this.sanitizer.bypassSecurityTrustHtml(htmlString);
    } else {
      const address = this.event?.getSportPageLocationDisplay()?.toUpperCase();
      const htmlString = `<i class="fas fa-map-marker-smile yellow-icon"></i> ${address}`;
      return this.sanitizer.bypassSecurityTrustHtml(htmlString);
    }
  }

  goToUaWebsite() {
    window.open('https://www.underarmour.de/de-de/', '_blank');
  }

  get isAccentSection(): boolean {
    return (
      !!this.section &&
      ([
        EventSectionType.TIMELINE,
        EventSectionType.FAQ,
        EventSectionType.FOOTER,
        EventSectionType.WISHLIST,
        EventSectionType.GALLERY,
      ].includes(this.section.type) ||
        (this.section.type === EventSectionType.LOCATION &&
          this.layout === EventTemplateLayout.ONE_COLUMN_MODERN))
    );
  }

  get eventTimetable(): EventTimetable | undefined {
    return this.event?.eventTemplate?.eventTimetable;
  }

  get eventWeddingParty(): EventWeddingParty | undefined {
    return this.event?.eventTemplate?.eventWeddingParty;
  }

  get eventFaqs(): EventFaq[] | undefined {
    return this.event?.eventTemplate?.eventFaqs;
  }

  get isAttendeeView(): boolean {
    return (
      !!this.loggedUser &&
      !!this.event?.getUserAttendeeRSVPOption(this.loggedUser.id) &&
      this.event?.isNonPendingAttendee(this.loggedUser.id)
    );
  }

  get isUaSection(): boolean {
    return !!this.event?.uri.includes('underarmour-infinite-elite-testival');
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  protected readonly EventSectionType = EventSectionType;
  protected readonly EventType = EventType;
  protected readonly EventSection = EventSection;
  protected readonly EventTemplateLayout = EventTemplateLayout;
  protected readonly ImagePosition = ImagePosition;
  protected readonly RSVPOptionType = RSVPOptionType;
  protected readonly EventSectionBackgroundSize = EventSectionBackgroundSize;
  protected readonly QuillUtils = QuillUtils;
}
