import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  PLATFORM_ID,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { CommonModule, isPlatformServer } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { EventTemplateLayout } from '@models/design-templates/event-template-layout.enum';
import { EventAttendeeStatus } from '@models/event-attendance/event-attendee-status.enum';
import { EventRSVPOption } from '@models/event-attendance/event-rsvp-option.model';
import { RSVPOptionType } from '@models/event-attendance/rsvp-option-type.enum';
import { ConfigurableSectionItem } from '@models/events/dto/configurable-section-item.model';
import { EventUpdateRequest } from '@models/events/dto/event-update.request';
import { EventAsset } from '@models/events/event-asset.model';
import { EventFaq } from '@models/events/event-faq.model';
import { EventSectionType } from '@models/events/event-section-type.enum';
import { EventTestimonial } from '@models/events/event-testimonial.model';
import { EventTimetableItem } from '@models/events/event-timetable-item.model';
import { EventWeddingPartyItem } from '@models/events/event-wedding-party-item.model';
import { EventWishlistItem } from '@models/events/event-wishlist-item.model';
import { Address } from '@models/shared/address.model';
import { AdvancedOneColumnConfigureSectionComponent } from '@modules/events/components/advanced-one-column-configure-section/advanced-one-column-configure-section.component';
import { EventAfterAttendOverlayDialogComponent } from '@modules/events/dialogs/event-after-attend-overlay-dialog/event-after-attend-overlay-dialog.component';
import { RsvpDialog } from '@modules/events/dialogs/rsvp/rsvp.dialog';
import { AddressPickerDialog } from '@modules/shared/dialogs/address-picker/address-picker.dialog';
import { DateTimeRangePickerDialog } from '@modules/shared/dialogs/date-time-range-picker/date-time-range-picker.dialog';
import { ImagePickerDialog } from '@modules/shared/dialogs/image-picker/image-picker.dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EventTemplateFrame } from '@models/events/event-template-frame.model';
import { AuthService } from '@services/auth/auth.service';
import { SidebarService } from '@services/sidebar.service';
import { DateUtils } from '@utils/date-utils';
import { FooterComponent } from 'app/components/footer/footer.component';
import { EventTemplateVisual } from '@models/design-templates/event-template-visual.model';
import { EventTemplateFont } from '@models/design-templates/event-template-font.model';
import { Event } from '@models/events/event.model';
import { FontService } from '@services/shared/font.service';
import { ScreenWidthService } from '@services/shared/screen-width.service';
import { User } from '@models/users/user.model';
import { AddToCalendarDialog } from '@modules/shared/dialogs/add-to-calendar/add-to-calendar.dialog';
import { environment as env } from '@environments/environment';
import {
  MatDialog,
  MatDialogRef,
  MatDialogState,
} from '@angular/material/dialog';
import { EventService } from '@services/events/event.service';
import { EventStore } from '@services/stores/event.store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Helpers } from '@utils/helpers';
import { EventWidget } from '@models/events/event-widget.enum';
import { CampaignEventRankingsResponse } from '@models/campaigns/dto/event-campaign-rating.response';
import { EventSection } from '@models/events/event-section.model';
import moment from 'moment-timezone';
import { TextEditableDirective } from '../../../../directives/text-editable.directive';
import { AppRoutes } from '../../../../routes';
import { EventSectionComponent } from '../event-section/event-section.component';
import { ScrollToModule, ScrollToService } from '@hungthuanmk/ngx-scroll-to';
import AOS from 'aos';

@Component({
  selector: 'app-weddingpage-event',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ScrollToModule,
    FooterComponent,
    EventSectionComponent,
    AdvancedOneColumnConfigureSectionComponent,
    FormsModule,
    TextEditableDirective,
  ],
  providers: [EventService, ScreenWidthService, ScrollToService],
  templateUrl: './weddingpage-event.component.html',
  styleUrl: './weddingpage-event.component.scss',
})
export class WeddingpageEventComponent
  implements OnInit, OnChanges, AfterViewInit, AfterViewChecked
{
  isServer = false;
  init = false;

  private _event?: Event;
  @Input() isVotingView?: boolean = false;
  @Input() isEdit?: boolean = false;
  dialogRef?: MatDialogRef<AddressPickerDialog>;
  @Input() set event(event: Event) {
    this._event = event;

    if (this.font) {
      const primaryLogoFont = this.font.primaryLogoFont;
      const bodyFont = this.font.bodyFont;
      const fontFamilies: string[] = [];
      if (primaryLogoFont) {
        fontFamilies.push(primaryLogoFont);
      }
      if (bodyFont) {
        fontFamilies.push(bodyFont);
      }
      fontFamilies.push('Bodoni Moda');

      this.fontService.updateFonts(fontFamilies);

      Helpers.setEventFonts(
        this.font.primaryLogoFont,
        this.font.bodyFont,
        this.font.bodyFontWeight,
      );
    }
  }

  get event(): Event | undefined {
    return this._event;
  }

  @Input() isHostView = false;

  @Input() loggedUser?: User;

  isAfterCreation = false;
  eventActionsSticky = false;
  isDesktop = true;
  isNotAttendingUser?: boolean;
  availableCapacity?: number;
  showMaxCapacity?: boolean;
  isCapacity?: boolean;
  isCampaign?: boolean = false;
  campaignData?: CampaignEventRankingsResponse;
  userOnWaitList: boolean = false;
  isSidebarOpen: boolean = false;
  configurableObject?: ConfigurableSectionItem;
  selectedSection?: EventSection;
  selectedTimeTableItem?: EventTimetableItem;
  selectedFaq?: EventFaq;
  selectedTestimonial?: EventTestimonial;
  selectedPartyItem?: EventWeddingPartyItem;
  selectedWishlistItem?: EventWishlistItem;
  selectedEventAsset?: EventAsset;
  layout?: EventTemplateLayout;

  imagePickerDialogRef?: MatDialogRef<ImagePickerDialog>;
  currentLang?: string = 'en';
  frame?: EventTemplateFrame;
  mainSection?: EventSection;
  lastSection?: EventSection;
  @ViewChild('effectVideo', { read: ElementRef, static: false })
  effectVideo?: ElementRef;

  constructor(
    private eventService: EventService,
    private eventStore: EventStore,
    private fontService: FontService,
    private screenWidthService: ScreenWidthService,
    private dialog: MatDialog,
    private authService: AuthService,
    private scrollToService: ScrollToService,
    protected sidebarService: SidebarService,
    private router: Router,
    private translateService: TranslateService,
    private viewContainerRef: ViewContainerRef,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone,
    @Inject(PLATFORM_ID) platformId: Object,
  ) {
    this.isServer = isPlatformServer(platformId);

    this.translateService.onLangChange
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.currentLang = this.translateService.currentLang;
      });

    this.eventStore.event.pipe(takeUntilDestroyed()).subscribe((event) => {
      if (event) {
        this.event = event;
        if (event.eventTemplate?.visual?.frameId) {
          this.frame = event.eventTemplate.visual.frame;
        }
        this.layout = event.eventTemplate?.layout;
        this.mainSection = this.getMainSection();
        this.lastSection = this.getLastSection();
        Helpers.setMainSectionTheme(
          this.mainSection?.backgroundColor,
          this.mainSection?.bodyColor,
        );
        this.ngZone.run(() => {
          this.cdr.markForCheck();
        });
        this.checkEffectVideoAutoplay();

        if (!this.init) {
          this.init = true;

          if (
            !this.isServer &&
            (this.event.eventTemplate?.layout ===
              EventTemplateLayout.ONE_COLUMN_MODERN ||
              this.event.eventTemplate?.layout ===
                EventTemplateLayout.ONE_COLUMN_ELEGANT)
          ) {
            AOS.init();
          }
        }
      }
    });

    this.screenWidthService
      .isDesktop()
      .pipe(takeUntilDestroyed())
      .subscribe({
        next: (isDesktop: boolean) => {
          this.isDesktop = isDesktop;
        },
      });
    this.screenWidthService
      .isOver992()
      .pipe(takeUntilDestroyed())
      .subscribe({
        next: (isOver992: boolean) => {
          this.eventActionsSticky = !isOver992;
        },
      });

    this.authService.userSubject
      .pipe(takeUntilDestroyed())
      .subscribe((user) => {
        if (user.id > 0) {
          this.loggedUser = user;
        }
      });
  }

  ngAfterViewInit(): void {
    this.checkEffectVideoAutoplay();
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
    if (!this.isServer) {
      AOS.refresh();
    }
  }

  get visualBackgroundUrl(): string | undefined {
    if (!this.visual?.background) {
      return undefined;
    }

    if (this.isDesktop || this.visual?.background?.mobileUrl === '') {
      return this.visual?.background?.url;
    } else {
      return this.visual?.background?.mobileUrl;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.font && this.isVotingView) {
      Helpers.setEventTheme(
        this.font.primaryLogoFontColor,
        this.font.bodyFontColor,
        this.visual?.backgroundColor,
      );
    }
  }

  checkEffectVideoAutoplay(): void {
    if (
      !this.effectVideo ||
      !this.effectVideo.nativeElement ||
      !this.effectVideo.nativeElement.play
    ) {
      return;
    }

    const promise = this.effectVideo.nativeElement.play();
    if (promise) {
      promise
        .then((_: any) => {})
        .catch((error: any) => {
          if (error.name === 'NotAllowedError') {
            this.effectVideo?.nativeElement.remove();
          }
        });
    }
  }
  ngOnInit(): void {
    this.onClose();
    const foundAttendee = this.event?.attendees?.find(
      (x) => x.userId === this.loggedUser?.id,
    );
    if (
      foundAttendee &&
      foundAttendee.status === EventAttendeeStatus.ON_WAITLIST
    ) {
      this.userOnWaitList = true;
    }

    this.loggedUser && this.checkIfUserAttending();
    this.calculateMaxCapacity();
    if (this.font && this.isVotingView) {
      Helpers.setEventTheme(
        this.font.primaryLogoFontColor,
        this.font.bodyFontColor,
        this.visual?.backgroundColor,
      );
    }
  }

  getMainSection() {
    return this.sections?.find((x) => x.type === EventSectionType.MAIN);
  }

  getLastSection() {
    return this.sections?.length
      ? this.sections[this.sections.length - 1]
      : undefined;
  }

  get visualEffectUrl(): string | undefined {
    if (!this.visual?.effect) {
      return undefined;
    }

    if (this.isDesktop || this.visual?.effect?.mobileUrl === '') {
      return this.visual?.effect?.url;
    } else {
      return this.visual?.effect?.mobileUrl;
    }
  }

  get visualEffectHevcUrl(): string | undefined {
    if (!this.visual?.effect) {
      return undefined;
    }

    if (this.isDesktop || this.visual?.effect?.h265MobileUrl === '') {
      return this.visual?.effect?.h265Url;
    } else {
      return this.visual?.effect?.h265MobileUrl;
    }
  }

  updateMainSection(value: string) {
    if (!this.event || !this.mainSection) return;
    const request = {
      ...this.mainSection,
      headline: value,
    };

    this.eventService
      .updateEvent(this.event.id, { section: request })
      .subscribe((event) => {
        this.event = event;
        this.eventStore.refreshEvent(this.event.uri);
      });
  }
  onConfigureSection(configurableObject: ConfigurableSectionItem) {
    if (!this.isSidebarOpen) {
      this.updateSelected(configurableObject);
      this.updateConfigurableObject();
      this.sidebarService.toggleEventSectionDetailsSidebarOpen();
    } else {
      this.updateSelected(configurableObject);
      this.updateConfigurableObject();
    }
  }

  formatStartDate() {
    if (!this.event || !this.event.startDate) {
      return;
    }
    let dateInfo =
      moment(this.event.startDate).format('DD') +
      '. ' +
      DateUtils.formatDateMonth(this.event.startDate, this.currentLang) +
      ' ' +
      moment(this.event.startDate).format('YYYY');

    return dateInfo;
  }
  updateSelected(configurableObject: ConfigurableSectionItem) {
    this.selectedSection = configurableObject.section;
    this.selectedTimeTableItem = configurableObject.timeTableItem;
    this.selectedFaq = configurableObject.faq;
    this.selectedTestimonial = configurableObject.testimonial;
    this.selectedPartyItem = configurableObject.partyItem;
    this.selectedWishlistItem = configurableObject.eventWishlistItem;
    this.selectedEventAsset = configurableObject.eventAsset;
  }

  openPickerDialog() {
    if (this.dialogRef && this.dialogRef.getState() !== MatDialogState.CLOSED) {
      return;
    }

    this.dialogRef = this.dialog.open(AddressPickerDialog, {
      maxWidth: '654px',
      maxHeight: '100vh',
      width: '100%',
      height: 'auto',
      data: {
        address: this.event?.eventAddress,
        isEventEditPicker: true,
      },
      panelClass: ['normal-dialog'],
    });
    this.dialogRef.afterClosed().subscribe((address: Address) => {
      if (address) {
        if (this.event) {
          this.event.eventAddress = address;
          const deleted = address === undefined;
          this.update({
            address: this.event.eventAddress,
            deleteAddress: deleted,
            newUser: {
              eventUuid: this.event.uuid,
            },
          });
        }
      }
    });
  }

  openDateTimePickerDialog(isRsvpDate: boolean = false) {
    if (!this.event || !this.isEdit) {
      return;
    }

    const dialogRef = this.dialog.open(DateTimeRangePickerDialog, {
      maxWidth: '602px',
      maxHeight: '100vh',
      width: '100%',
      height: 'auto',
      data: {
        event: this.event,
      },
      panelClass: ['normal-dialog'],
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res?.dateRange && this.event) {
        if (isRsvpDate) {
          this.event.rsvpDisableDate = res.dateRange.startDate;
        } else {
          this.event.startDate = res.dateRange.startDate;
          this.event.endDate = res.dateRange.endDate;
        }
        this.event.timeZone = res.dateRange.timeZone;

        const zeroDate = '0001-01-01T00:00:00Z';
        if (isRsvpDate) {
          this.update({
            confirmNoPackages: res.confirmNoPackages,
            rsvpDisableDate: this.event.rsvpDisableDate || zeroDate,
            timeZone: this.event.timeZone,
            newUser: {
              eventUuid: this.event.uuid,
            },
          });
        } else {
          this.update({
            confirmNoPackages: res.confirmNoPackages,
            startDate: this.event.startDate || zeroDate,
            endDate: this.event.endDate || zeroDate,
            timeZone: this.event.timeZone,
            newUser: {
              eventUuid: this.event.uuid,
            },
          });
        }
      }
    });
  }
  openImagePicker() {
    if (!this.isEdit) return;

    if (
      this.imagePickerDialogRef &&
      this.imagePickerDialogRef.getState() !== MatDialogState.CLOSED
    ) {
      return;
    }

    this.imagePickerDialogRef = this.dialog.open(ImagePickerDialog, {
      maxWidth: '602px',
      maxHeight: '100vh',
      width: '100%',
      height: 'auto',
      data: {
        event: this.event,
        eventImagePicker: true,
        loggedUser: this.loggedUser,
      },
      panelClass: ['normal-dialog'],
    });

    this.imagePickerDialogRef.afterClosed().subscribe((res) => {
      if (res) {
        if (res.event) {
          this.event = res.event;
        }

        if (this.event && res.imageUrl && res.imageUrl !== '') {
          this.event.mainPictureUrl = res.imageUrl;
        }
      }
    });
  }

  updateConfigurableObject() {
    this.configurableObject = {
      section: this.selectedSection,
      faq: this.selectedFaq,
      timeTableItem: this.selectedTimeTableItem,
      testimonial: this.selectedTestimonial,
      partyItem: this.selectedPartyItem,
      eventWishlistItem: this.selectedWishlistItem,
      eventAsset: this.selectedEventAsset,
    };
  }

  get userAttendeeRSVPOption(): EventRSVPOption | undefined {
    return this.loggedUser?.id
      ? this.event?.getUserAttendeeRSVPOption(this.loggedUser.id)
      : undefined;
  }

  onRSVP(type: RSVPOptionType) {
    if (this.isEdit) {
      return;
    }
    let notGoing = false;
    if (this.event?.hasOneButtonSignUp()) {
      if (type === RSVPOptionType.NO) {
        notGoing = true;
      }
    }

    if (
      this.event?.hasTwoButtonSignUp() &&
      type === RSVPOptionType.YES &&
      this.isAttendeeView
    ) {
      notGoing = true;
    }

    const dialogRef = this.dialog.open(RsvpDialog, {
      viewContainerRef: this.viewContainerRef,
      maxWidth: '602px',
      maxHeight: '100vh',
      width: '100%',
      height: 'auto',
      data: {
        event: this.event,
        rsvpType: type,
        isAttendee: !!this.userAttendeeRSVPOption,
        notGoing: notGoing,
        onWaitlist:
          this.event?.maxCapacityReached() && this.event.waitlistEnabled,
      },
      panelClass: ['normal-dialog', 'rsvp-dialog', 'overlay-theme'],
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res && this.event) {
        if (this.event.isPreviewEvent) {
          return;
        }

        if (res.refresh) {
          this.eventStore.refreshEvent(this.event.uri);
        }

        if (res.openEventAfterAttendOverlayDialog) {
          this.dialog.open(EventAfterAttendOverlayDialogComponent, {
            width: '100%',
            height: '100%',
            maxWidth: '100%',
            panelClass: 'overlay-page-dialog',
            data: {
              event: this.event,
              rsvpType: res.rsvpType || type,
              onWaitlist:
                this.event?.maxCapacityReached() && this.event.waitlistEnabled,
            },
          });
        }
      } else {
        if (this.event) {
          this.eventStore.refreshEvent(this.event.uri);
        }
      }
    });
  }
  calculateMaxCapacity() {
    if (!this.event?.maxCapacity || this.event.maxCapacity === 0) {
      this.showMaxCapacity = false;
      this.isCapacity = true; // There is no limit
    } else {
      const guestsGoing = this.event.getNumYesAttendees() || 0;
      const availablePlaces = this.event.maxCapacity - guestsGoing;

      if (availablePlaces > 0) {
        this.availableCapacity = availablePlaces;
        this.showMaxCapacity = true;
      } else {
        this.showMaxCapacity = false;
      }

      this.isCapacity = this.showMaxCapacity || guestsGoing === 0;
    }
  }

  checkIfUserAttending() {
    const loggedInUserId = this.loggedUser?.id;
    const eventAttendees = this.event?.attendees?.map((x) => x.user?.id);
    const eventHosts = this.event?.hosts?.map((x) => x.user?.id);

    const isNotHost = this.event?.hostUserId !== loggedInUserId;
    const isNotCoHost = !eventAttendees?.includes(loggedInUserId);
    const isNotAttendee = !eventHosts?.includes(loggedInUserId);

    this.isNotAttendingUser = isNotHost && isNotCoHost && isNotAttendee;
  }

  onRefreshEvent() {
    if (this.event) {
      const params = new Map<string, string>();
      const userLang = localStorage.getItem('userLang');
      if (userLang) {
        params.set('userLang', userLang);
      }
      this.eventService.getByUri(this.event.uri, params).subscribe({
        next: (event) => {
          this.event = event;
        },
      });
    }
  }

  onEventDescriptionChange(val: string) {
    if (this.event && this.event.description !== val) {
      this.event.description = val;
      this.update({
        description: this.event.description,
        newUser: {
          eventUuid: this.event.uuid,
        },
      });
    }
  }

  onSectionActionChange(val: string) {
    if (this.event && this.event.actionSectionTitle !== val) {
      this.event.actionSectionTitle = val;
      this.update({
        actionSectionTitle: this.event.actionSectionTitle,
        newUser: {
          eventUuid: this.event.uuid,
        },
      });
    }
  }

  onPaste(event: ClipboardEvent) {
    event.preventDefault();

    const clipboardData = event.clipboardData;
    const pastedText = clipboardData?.getData('text/plain');

    if (pastedText) {
      document.execCommand('insertText', false, pastedText);

      const updatedText = (event.target as HTMLElement).innerText;
      this.onEventDescriptionChange(updatedText);
    }
  }
  shareEvent() {
    if (this.event) {
      window.navigator.share({
        text: this.event.description,
        title: this.event.name,
        url: this.event.getLink(),
      });
    }
  }

  addToCalendar() {
    this.dialog.open(AddToCalendarDialog, {
      maxWidth: '602px',
      maxHeight: '100vh',
      width: '100%',
      height: 'auto',
      data: this.event,
      panelClass: ['normal-dialog', 'event-dialog', 'overlay-theme'],
    });
  }

  update(req?: EventUpdateRequest) {
    if (this.event) {
      if (req) {
        req.localization = this.translateService.currentLang;
      }

      this.eventService.updateEvent(this.event.id, req).subscribe((event) => {
        if (event.uri !== this.event!.uri) {
          this.router.navigate(['/', AppRoutes.Events.events, event.uri], {
            replaceUrl: true,
          });
        }
        this.event = event;
        this.eventStore.setEvent(event);
      });
    }
  }

  onEventNameChange(val: string) {
    if (this.event && this.event.name !== val) {
      this.event.name = val;
      this.update({
        name: this.event.name,
        newUser: {
          eventUuid: this.event.uuid,
        },
      });
    }
  }

  get sections(): EventSection[] | undefined {
    return this.event?.eventTemplate?.getEventSectionsOrdered();
  }

  get visual(): EventTemplateVisual | undefined {
    return this.event?.eventTemplate?.visual;
  }

  get font(): EventTemplateFont | undefined {
    return this.event?.eventTemplate?.font;
  }

  get isAttendeeView(): boolean {
    return (
      !!this.loggedUser &&
      !!this.event?.getUserAttendeeRSVPOption(this.loggedUser.id) &&
      this.event.isNonPendingAttendee(this.loggedUser.id)
    );
  }

  get widgets(): EventWidget[] | undefined {
    return this.event?.eventTemplate?.widgets;
  }

  get isUserHost(): boolean {
    return (
      !!this.loggedUser &&
      !!this.loggedUser.id &&
      !!this.event &&
      (this.event.isUserHost(this.loggedUser.id) ||
        this.event.isNotLoggedUserHost())
    );
  }

  onClose() {
    this.sidebarService.sidebarEventSectionDetailsStateSubject.next(false);
  }

  protected readonly EventWidget = EventWidget;
  protected readonly EventTemplateLayout = EventTemplateLayout;
  protected readonly RSVPOptionType = RSVPOptionType;
  protected env = env;
}
