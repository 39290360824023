import { Component, Input, OnInit } from '@angular/core';
import { EventTemplateLayout } from '@models/design-templates/event-template-layout.enum';
import { EventTimetableItem } from '@models/events/event-timetable-item.model';
import { EventType } from '@models/events/event-type.enum';
import { Event } from '@models/events/event.model';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-event-section-timetable-item',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './event-section-timetable-item.component.html',
  styleUrl: './event-section-timetable-item.component.scss',
})
export class EventSectionTimetableItemComponent implements OnInit {
  @Input() item?: EventTimetableItem;
  @Input() eventType: EventType = EventType.INVITATION;
  @Input() isUaEvent = false;
  @Input() event?: Event;
  layout?: EventTemplateLayout;
  protected readonly EventType = EventType;

  ngOnInit(): void {
    if (this.event) {
      this.layout = this.event.eventTemplate?.layout;
    }
  }

  protected readonly EventTemplateLayout = EventTemplateLayout;
}
