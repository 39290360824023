import { EventTemplate } from '@models/design-templates/event-template.model';
import { EventType } from '@models/events/event-type.enum';
import { User } from '@models/users/user.model';

export class TemplateUtils {
  static canApplyProTemplate(eventTemplate: EventTemplate, loggedUser?: User) {
    return !Boolean(
      !loggedUser?.isPro() &&
        eventTemplate?.type &&
        eventTemplate?.type !== EventType.PARTYPAGE,
    );
  }
}
