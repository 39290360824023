@if (item) {
  <div
    class="wedding-party-item"
    [class.family]="item.type === EventWeddingPartyItemType.FAMILY"
  >
    @if (item.type === EventWeddingPartyItemType.HONOR_ATTENDANT || item.type === EventWeddingPartyItemType.BRIDE_AND_GROOM) {
      <div class="person-img-cont">
        <img [src]="item.pictureUrl" alt="Honor attendant image" />
        <div class="person-name">{{ item.name }}</div>
      </div>
    } @else if (item.type === EventWeddingPartyItemType.FAMILY) {
      <img
        [src]="item.pictureUrl"
        alt="Honor attendant image"
        class="family-img"
      />
    }
    <div class="description" [innerText]="item.description"></div>
  </div>
}
