export enum EventSectionType {
  MAIN = 'MAIN',
  TIMELINE = 'TIMELINE',
  LOCATION = 'LOCATION',
  GROOMSMEN = 'GROOMSMEN',
  RSVP = 'RSVP',
  WISHLIST = 'WISHLIST',
  FAQ = 'FAQ',
  NORMAL = 'NORMAL',
  GALLERY = 'GALLERY',
  FOOTER = 'FOOTER',
  SPONSORS = 'SPONSORS',
  TESTIMONIALS = 'TESTIMONIALS',
  DATE_LOCATION = 'DATE_LOCATION',
  EVENT_LIST = 'EVENT_LIST',
  INSTAGRAM_POSTS = 'INSTAGRAM_POSTS',
  NEWS = 'NEWS',
  EVENT_TICKETS = 'EVENT_TICKETS',
  PICTURE_INFO = 'PICTURE_INFO',
  HEADER = 'HEADER',
}
