import { NgOptimizedImage } from '@angular/common';
import { Component, Input } from '@angular/core';
import { EventSponsor } from '@models/events/event-sponsor.model';
import { EventType } from '@models/events/event-type.enum';
import { Event } from '@models/events/event.model';

@Component({
  selector: 'app-event-section-sponsor-item',
  standalone: true,
  imports: [NgOptimizedImage],
  templateUrl: './event-section-sponsor-item.component.html',
  styleUrl: './event-section-sponsor-item.component.scss',
})
export class EventSectionSponsorItemComponent {
  @Input() sponsor?: EventSponsor;
  @Input() event?: Event;

  navigateTo(url?: string) {
    if (url) {
      window.open(url, '_blank');
    }
  }

  isGeneric() {
    const genericEvents = [
      EventType.GENERIC_LANDINGPAGE,
      EventType.GENERIC_EVENT,
    ];
    return genericEvents.includes(<EventType>this.event?.type);
  }
}
