import {
  CdkDrag,
  CdkDragDrop,
  CdkDragHandle,
  CdkDropList,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { NgForOf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { EventUpdateRequest } from '@models/events/dto/event-update.request';
import { EventWeddingPartyItem } from '@models/events/event-wedding-party-item.model';
import { Event } from '@models/events/event.model';
import { getAssetFromURL } from '@models/shared/asset.model';
import { YesNoDialog } from '@modules/customer/dialogs/yes-no/yes-no.dialog';
import { UpdateActions } from '@modules/events/components/event-section-testimonials/event-section-testimonials.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EventService } from '@services/events/event.service';
import { NotificationService } from '@services/shared/notification.service';
import { EventStore } from '@services/stores/event.store';
import { CamelCasePipe } from '../../../../pipes/camel-case.pipe';
import { EventSectionWeddingPartyItemComponent } from '../event-section-wedding-party-item/event-section-wedding-party-item.component';

@Component({
  selector: 'app-event-section-wedding-party',
  standalone: true,
  imports: [
    EventSectionWeddingPartyItemComponent,
    CamelCasePipe,
    CdkDrag,
    CdkDragHandle,
    TranslateModule,
    CdkDropList,
    NgForOf,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem,
  ],
  providers: [NotificationService],
  templateUrl: './event-section-wedding-party.component.html',
  styleUrl: './event-section-wedding-party.component.scss',
})
export class EventSectionWeddingPartyComponent {
  @Input() event?: Event;
  @Input() isEdit?: boolean = false;
  @Output() onEdit = new EventEmitter();
  @Output() configureSection = new EventEmitter<void>();

  private _eventWeddingPartyItems: EventWeddingPartyItem[] = [];
  get eventWeddingPartyItems(): EventWeddingPartyItem[] | undefined {
    return this._eventWeddingPartyItems;
  }
  @Input()
  set eventWeddingPartyItems(value: EventWeddingPartyItem[] | undefined) {
    if (value) {
      this._eventWeddingPartyItems = value.sort((a, b) => a.order - b.order);
    }
  }

  draggedItem?: EventWeddingPartyItem;
  selectedItem?: EventWeddingPartyItem;

  constructor(
    private dialog: MatDialog,
    private eventService: EventService,
    private translateService: TranslateService,
    private eventStore: EventStore,
    private notificationService: NotificationService,
  ) {}

  onUpdate(action: UpdateActions, item: EventWeddingPartyItem) {
    if (action === UpdateActions.EDIT) {
      this.onEdit.emit({
        partyItem: item,
      });
    } else if (action === UpdateActions.COPY) {
      const newItem = {
        isCopy: true,
        id: 0,
        type: item.type,
        name: item.name,
        pictureAsset: item.pictureUrl
          ? getAssetFromURL(item.pictureUrl)
          : undefined,
        description: item.description,
      };
      const eventRequest: EventUpdateRequest = {
        weddingPartyItem: newItem,
      };
      this.update(eventRequest);
    } else if (action === UpdateActions.DELETE) {
      this.removeItem(item);
    }
  }

  removeItem(item: EventWeddingPartyItem) {
    const dialogRef = this.dialog.open(YesNoDialog, {
      maxWidth: '602px',
      maxHeight: '100vh',
      width: '100%',
      height: 'auto',
      data: {
        title: 'APP.ADVANCED_ONE_COLUMN_EVENT.DELETE_ITEM_TITLE',
        message: 'APP.ADVANCED_ONE_COLUMN_EVENT.DELETE_ITEM_CONFIRMATION',
        yesLabel: 'APP.YES',
        font: this.event?.eventTemplate?.font,
        isDeleteDialog: true,
      },
      panelClass: ['normal-dialog'],
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res && this.event) {
        this.eventService
          .updateEvent(this.event?.id, {
            weddingPartyItemDeleteId: item.id,
          })
          .subscribe({
            next: () => {
              if (this.event?.uri) {
                this.eventStore.refreshEvent(this.event?.uri);
              }
            },
            error: () => {
              this.notificationService.error(
                this.translateService.instant(
                  'APP.ADVANCED_ONE_COLUMN_EVENT.DELETE_ERROR',
                ),
              );
            },
          });
      }
    });
  }

  update(req?: EventUpdateRequest) {
    if (this.event) {
      if (req) {
        req.localization = this.translateService.currentLang;
      }

      this.eventService.updateEvent(this.event.id, req).subscribe((event) => {
        this.event = event;
        this.eventStore.setEvent(event);
      });
    }
  }

  drop(event: CdkDragDrop<any[]>, draggedItem?: EventWeddingPartyItem) {
    if (this.eventWeddingPartyItems)
      moveItemInArray(
        this.eventWeddingPartyItems,
        event.previousIndex,
        event.currentIndex,
      );

    if (draggedItem) {
      this.eventService
        .updateEvent(this.event?.id, {
          weddingPartyItem: {
            id: this.draggedItem?.id,
            order: event.currentIndex,
          },
        })
        .subscribe({
          next: () => {
            if (this.event) this.eventStore.refreshEvent(this.event.uri);
          },
        });
    }
  }

  onConfigureSection() {
    this.configureSection.emit();
  }

  onSelectItem(item: EventWeddingPartyItem) {
    this.selectedItem = item !== this.selectedItem ? item : undefined;
  }

  trackByFn(index: number, item: any) {
    return item.id;
  }

  protected readonly UpdateActions = UpdateActions;
}
