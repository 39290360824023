
@if(isEdit){
  <div
    cdkDropList
    cdkDropListOrientation="vertical"
    (cdkDropListDropped)="drop($event, draggedItem)"
    class="wedding-items-drop-list vertical-view"
  >
    <div class='items-wrapper'>
      <div
        *ngFor="let item of eventWeddingPartyItems; let i = index; trackBy: trackByFn"
        cdkDrag
        class="wedding-item"
        [class.selected]='item === selectedItem'
        (cdkDragStarted)="draggedItem = item"
      >

        <div cdkDragHandle class="grab-action">
          <i class="fas fa-grip-vertical"></i>
        </div>

        <app-event-section-wedding-party-item
          [item]="item"
          (click)='onSelectItem(item)'
        ></app-event-section-wedding-party-item>

        <div
          class='menu-trigger'
          [matMenuTriggerFor]="menu">
          <i class="far fa-ellipsis-v"></i>
        </div>
        <mat-menu #menu="matMenu" class="table-actions-container">
          <button mat-menu-item (click)='onUpdate(UpdateActions.EDIT, item)'>
              <span class="menu-item-content">
                <i class="far fa-pen"></i>
                <span class="text">{{ 'APP.EDIT' | translate | camelCase }}</span>
              </span>
          </button>

          <button mat-menu-item (click)='onUpdate(UpdateActions.COPY, item)'>
              <span class="menu-item-content">
                <i class="far fa-copy"></i>
                <span class="text"
                >{{ 'APP.COPY' |
                  translate | camelCase }}</span
                >
              </span>
          </button>

          <button mat-menu-item (click)='onUpdate(UpdateActions.DELETE, item)'>
              <span class="menu-item-content">
                <i class="far fa-trash"></i>
                <span class="text"
                >{{ 'APP.DELETE' |
                  translate | camelCase }}</span
                >
              </span>
          </button>
        </mat-menu>
      </div>
    </div>
    <button
      class="btn btn-primary add-button-small"
      (click)="onConfigureSection()"
    >
      <i class="far fa-plus"></i>
    </button>
  </div>
}@else{
  <div class="event-wedding-party">
    @for (item of eventWeddingPartyItems; track item.id) {
      <app-event-section-wedding-party-item
        [item]="item"
      ></app-event-section-wedding-party-item>
    }
  </div>
}
