@if (event && visual && font && sections) {
  <div
    class="page-container"
    [class.logged-user-view]="loggedUser"
    [class.elegant-layout]="layout === EventTemplateLayout.ONE_COLUMN_ELEGANT"
    [class.modern-layout]="layout === EventTemplateLayout.ONE_COLUMN_MODERN"
    [class.host-view]="isHostView"
    [class.edit-mode]="isEdit"
  >
    @if (layout !== EventTemplateLayout.ONE_COLUMN_MODERN) {
      <div
        class="main-content"
        [ngStyle]="{
          'background-image': 'url(' + event.getEncodedMainImage() + ')',
          'background-color': visual.backgroundColor
        }"
      >
        @if (isEdit) {
          <div class="upload-icon" (click)="openImagePicker()">
            <i class="fa fa-upload"></i>
          </div>
        }

        <div class="overlay"></div>
        <div class="event-info-cont">
          @if (layout === EventTemplateLayout.ONE_COLUMN_ELEGANT) {
            @if (isEdit) {
              <div
                #descriptionEdit
                text-editable
                class="event-edit-textarea event-description elegant body-font"
                [attr.placeholder]="
                  'APP.EVENT_EDIT.DESCRIPTION_PLACEHOLDER' | translate
                "
                required
                [maxlength]="1000"
                #descriptionDiv="ngModel"
                [ngModel]="event.description"
                (ngModelChange)="onEventDescriptionChange($event)"
                (paste)="onPaste($event)"
                [innerText]="event.description"
                style="white-space: pre-wrap"
              >
                {{ event.description }}
              </div>
              <div
                text-editable
                class="event-edit-textarea event-name primary-font elegant"
                [style.font-weight]="
                  font.primaryFontWeight ? font.primaryFontWeight : null
                "
                [attr.placeholder]="
                  'APP.EVENT_EDIT.NAME_PLACEHOLDER' | translate
                "
                required
                maxlength="51"
                #nameDiv="ngModel"
                [ngModel]="mainSection?.headline"
                (ngModelChange)="updateMainSection($event)"
              >
                {{ mainSection?.headline || event.name }}
              </div>
              <div class="date elegant" (click)="openDateTimePickerDialog()">
                {{ formatStartDate() || "APP.EVENT.DATE_TBD" | translate }}
              </div>
            } @else {
              <div class="event-description body-font elegant">
                {{ event.description }}
              </div>
              <div class="event-name primary-font elegant">
                {{ mainSection?.headline || event.name }}
              </div>
              <div class="date elegant">
                {{ formatStartDate() }}
              </div>
            }
          } @else {
            @if (isEdit) {
              <div
                text-editable
                class="event-edit-textarea event-name primary-font"
                [style.font-weight]="
                  font.primaryFontWeight ? font.primaryFontWeight : null
                "
                [attr.placeholder]="
                  'APP.EVENT_EDIT.NAME_PLACEHOLDER' | translate
                "
                required
                maxlength="51"
                #nameDiv="ngModel"
                [ngModel]="event.name"
                (ngModelChange)="onEventNameChange($event)"
              >
                {{ event.name }}
              </div>
              <div class="description-info">
                <div
                  #descriptionEdit
                  text-editable
                  class="event-edit-textarea event-description body-font"
                  [attr.placeholder]="
                    'APP.EVENT_EDIT.DESCRIPTION_PLACEHOLDER' | translate
                  "
                  required
                  [maxlength]="1000"
                  #descriptionDiv="ngModel"
                  [ngModel]="event.description"
                  (ngModelChange)="onEventDescriptionChange($event)"
                  (paste)="onPaste($event)"
                  [innerText]="event.description"
                  style="white-space: pre-wrap"
                >
                  {{ event.description }}
                </div>

                <div class="date" (click)="openDateTimePickerDialog()">
                  @if (event.startDate) {
                    {{ event.startDate | date: "dd.MM.yyyy" }}
                  } @else {
                    <div>
                      {{ "APP.EVENT.DATE_TBD" | translate }}
                    </div>
                  }
                </div>
                @if (event.eventAddress) {
                  <div class="date" (click)="openPickerDialog()">
                    @if (isDesktop) {
                      | &nbsp;
                    }
                    {{ event.eventAddress.toDisplayForEventPrimary() }}
                  </div>
                }
              </div>
            } @else {
              <div class="event-name primary-font">{{ event.name }}</div>
              <div class="description-info">
                <div class="event-description body-font">
                  {{ event.description }}
                </div>
                <div class="date">
                  @if (event.startDate) {
                    {{ event.getStartDate() | date: "dd.MM.yyyy" }}
                  }
                </div>
                @if (event.eventAddress) {
                  <div class="date">
                    @if (isDesktop) {
                      | &nbsp;
                    }
                    {{ event.eventAddress.toDisplayForEventPrimary() }}
                  </div>
                }
              </div>
            }
          }
        </div>
        @if (layout === EventTemplateLayout.ONE_COLUMN_ELEGANT) {
          @if(this.isEdit){
            <div class="to-rsvp elegant" (click)="onRSVP(RSVPOptionType.YES)">
              <i class="far fa-arrow-right"></i>
              <div
                text-editable
                required
                [attr.placeholder]="
                    'APP.EVENT_RSVP.TITLE' | translate
                  "
                [maxlength]="1000"
                #descriptionDiv="ngModel"
                [ngModel]="event.actionSectionTitle"
                (ngModelChange)="onSectionActionChange($event)"
                (paste)="onPaste($event)"
                [innerText]="event.actionSectionTitle"
                style="white-space: pre-wrap"
              >
                @if(event.actionSectionTitle === ""){
                  {{ 'APP.EVENT_RSVP.TITLE' | translate}}
                }@else{
                  {{ event.actionSectionTitle }}
                }

              </div>
            </div>
          }@else{
            <div class="to-rsvp elegant" (click)="onRSVP(RSVPOptionType.YES)">
              <i class="far fa-arrow-right"></i>
              {{ event.actionSectionTitle || "APP.EVENT_RSVP.TITLE" | translate }}
            </div>
          }

        } @else {
          <div class="rsvp-btn-cont">
            <div
              class="btn btn-weddingpage-transparent"
              (click)="onRSVP(RSVPOptionType.YES)"
            >
              {{ "APP.EVENT_RSVP.LET_US_KNOW" | translate }}
            </div>
            @if (event.getRsvpDisableDate()) {
              <div
                class="rsvp-disable-date"
                (click)="openDateTimePickerDialog(true)"
              >
                {{ "APP.EVENT_RSVP.UNTIL" | translate }}
                @if (event.getRsvpDisableDate()) {
                  {{ event.getRsvpDisableDate() | date: "dd. MMMM yyyy" }}
                } @else {
                  {{ "APP.EVENT.DATE_TBD" | translate }}
                }
              </div>
            }
          </div>
        }
      </div>
    }

    <div
      class="event-sections"
      [class.with-frame]="frame?.hasHeaderFrame()"
      [ngStyle]="{
        background:
          frame?.hasHeaderFrame() && visualBackgroundUrl
            ? 'transparent'
            : (sections[0].backgroundColor || 'var(--event-section-background-color)')
      }"
      [class.one-column]="layout === EventTemplateLayout.ONE_COLUMN_MODERN"
      [class.edit-mode]="isEdit"
    >
      @if (frame && frame.hasHeaderFrame()) {
        <div
          class="frame-pattern"
          [class.logged-user-view]="loggedUser"
          [class.edit-mode]="isEdit"
          [style.backgroundImage]="
            'url(' + (isDesktop ? frame.headerUrl : frame.headerMobileUrl) + ')'
          "
          [ngStyle]="{
        backgroundColor:
          frame && frame.hasHeaderFrame() && visualBackgroundUrl
            ? 'transparent'
            : sections[0].backgroundColor
      }"
        ></div>
      }
      @for (section of sections; track $index) {
        <app-event-section
          [class.first-section]="$index === 0"
          [class.last-section]="$index === sections.length - 1"
          [isEdit]="isEdit"
          [isHostView]="isHostView"
          [layout]="layout"
          [section]="section"
          [loggedUser]="loggedUser"
          (updateEvent)="update($event)"
          (editSection)="onConfigureSection($event)"
          (changeDate)="openDateTimePickerDialog(false)"
          (changeAddress)="openPickerDialog()"
          (onRsvpChangeTitle)="onSectionActionChange($event)"
          (onRsvp)="onRSVP(RSVPOptionType.YES)"
          (onPaste)="onPaste($event)"
        ></app-event-section>
      }
      <app-footer
        class="after-footer"
        [showLogo]="true"
        [bodyColor]='lastSection?.bodyColor'
        [backgroundColor]='lastSection?.backgroundColor'
        [includePoweredBy]="true"
      ></app-footer>

      @if (frame && frame.hasFooterFrame()) {
        <div
          class="frame-pattern footer-frame"
          [class.logged-user-view]="loggedUser"
          [class.edit-mode]="isEdit"
          [style.backgroundImage]="
            'url(' + (isDesktop ? frame.footerUrl : frame.footerMobileUrl) + ')'
          "
        ></div>
      }
    </div>
  </div>
  @if (isEdit) {
    <app-advanced-one-column-configure-section
      [configurableObject]="configurableObject"
      [hideDescription]="
        layout !== EventTemplateLayout.ONE_COLUMN_MODERN &&
        layout !== EventTemplateLayout.ONE_COLUMN_ELEGANT
      "
      (close)="onClose()"
    />
  }

  @if (visualEffectUrl) {
    <div
      class="effect-overlay"
      [style.background-image]="
        visualEffectUrl && visual.isEffectImage()
          ? 'url(' + visualEffectUrl + ')'
          : ''
      "
      [class.host-view]="isHostView"
      [class.one-time-effect]="!visual.effect?.repeat"
    >
      @if (!visual.isEffectImage()) {
        <video
          #effectVideo
          class="effect-video"
          [muted]="'muted'"
          autoplay
          playsinline
          [loop]="visual.effect?.repeat"
        >
          @if (visualEffectHevcUrl !== "") {
            <source [src]="visualEffectHevcUrl" type="video/mp4;codecs=hvc1" />
          }
          <source
            [src]="visualEffectUrl"
            [type]="visual.effect?.mimeType || 'video/mp4'"
          />
        </video>
      }
    </div>
  }
}
