import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { interval, Subscription } from 'rxjs';
import moment from 'moment';
@Component({
  selector: 'app-event-start-date-timer',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './event-start-date-timer.component.html',
  styleUrl: './event-start-date-timer.component.scss',
})
export class EventStartDateTimerComponent implements OnInit, OnDestroy {
  private _startDate?: Date;

  @Input()
  set startDate(value: Date | undefined) {
    this._startDate = value;
    this.updateTime();
  }

  get startDate(): Date | undefined {
    return this._startDate;
  }

  months: number = 0;
  days: number = 0;
  hours: number = 0;
  minutes: number = 0;

  private timerSubscription?: Subscription;

  ngOnInit(): void {
    this.updateTime();
    this.timerSubscription = interval(60000).subscribe(() => this.updateTime());
  }

  private updateTime() {
    if (!this.startDate) return;

    const now = moment();
    const start = moment(this.startDate);

    if (start.isBefore(now)) {
      this.months = this.days = this.hours = this.minutes = 0;
      return;
    }

    this.months = start.diff(now, 'months');
    now.add(this.months, 'months');

    this.days = start.diff(now, 'days');
    now.add(this.days, 'days');

    this.hours = start.diff(now, 'hours');
    now.add(this.hours, 'hours');

    this.minutes = start.diff(now, 'minutes');
  }

  ngOnDestroy(): void {
    this.timerSubscription?.unsubscribe();
  }
}
