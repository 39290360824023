export enum EventWeddingPartyItemType {
  HONOR_ATTENDANT = 'HONOR_ATTENDANT',
  FAMILY = 'FAMILY',
  BRIDE_AND_GROOM = 'BRIDE_AND_GROOM',
}
export namespace EventWeddingPartyItemType {
  export function getAllEventWeddingPartyItemTypes(): EventWeddingPartyItemType[] {
    return [
      EventWeddingPartyItemType.HONOR_ATTENDANT,
      EventWeddingPartyItemType.FAMILY,
      EventWeddingPartyItemType.BRIDE_AND_GROOM,
    ];
  }

  export function getDisplayName(type?: EventWeddingPartyItemType): string {
    switch (type) {
      case EventWeddingPartyItemType.HONOR_ATTENDANT:
        return 'APP.WEDDING_PAGE.WEDDING_PARTY_ITEMS.HONOR_ATTENDANT';
      case EventWeddingPartyItemType.FAMILY:
        return 'APP.WEDDING_PAGE.WEDDING_PARTY_ITEMS.FAMILY';
      case EventWeddingPartyItemType.BRIDE_AND_GROOM:
        return 'APP.WEDDING_PAGE.WEDDING_PARTY_ITEMS.BRIDE_AND_GROOM';
      default:
        return '';
    }
  }
}
