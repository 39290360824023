@if ((attendee || communityMember) && form) {
  <form [formGroup]="form" class="tab-content">
    <app-user-avatar
      class="avatar"
      [user]="attendee?.userFull || attendee?.user || communityMember?.user"
      [size]="80"
      [placeholderFontSize]="40"
      [fallbackInitials]="
        communityMember
          ? communityMember.user?.getInitials()
          : attendee?.getFallbackInitials()
      "
    >
    </app-user-avatar>

    @if (attendee?.userId || communityMember?.userId) {
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{
          "APP.SETTINGSPAGE.GENERAL.FIRST_LAST_NAME" | translate
        }}</mat-label>
        <input type="text" matInput formControlName="name" />
        <div
          matSuffix
          class="icon-suffix"
          matTooltip="{{
            'APP.EVENT_RSVP.DATA_CHANGE_NOT_ALLOWED' | translate
          }}"
          matTooltipPosition="above"
        >
          <i class="fal fa-info-circle"></i>
        </div>
      </mat-form-field>
    } @else {
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{ "APP.FIRST_NAME" | translate }}</mat-label>
        <input
          type="text"
          matInput
          formControlName="firstName"
          (change)="updateState('firstName', $event)"
        />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{ "APP.LAST_NAME" | translate }}</mat-label>
        <input
          type="text"
          matInput
          formControlName="lastName"
          (change)="updateState('lastName', $event)"
        />
      </mat-form-field>
    }
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>{{ "APP.SETTINGSPAGE.GENERAL.EMAIL" | translate }}</mat-label>
      <input
        type="text"
        matInput
        formControlName="email"
        (change)="updateState('email', $event)"
      />
      @if (attendee?.userId || communityMember?.userId) {
        <div
          matSuffix
          class="icon-suffix"
          matTooltip="{{
            'APP.EVENT_RSVP.DATA_CHANGE_NOT_ALLOWED' | translate
          }}"
          matTooltipPosition="above"
        >
          <i class="fal fa-info-circle"></i>
        </div>
      }
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-100 tel-input-field">
      <app-tel-input
        formControlName="phone"
        (change)="updateState('phone', $event)"
      ></app-tel-input>
      @if (attendee?.userId || communityMember?.userId) {
        <div
          matSuffix
          class="icon-suffix"
          matTooltip="{{
            'APP.EVENT_RSVP.DATA_CHANGE_NOT_ALLOWED' | translate
          }}"
          matTooltipPosition="above"
        >
          <i class="fal fa-info-circle"></i>
        </div>
      }
    </mat-form-field>
    @if (communityMember) {
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Birthday</mat-label>
        <input type="text" matInput formControlName="birthday" />
        @if (communityMember.userId) {
          <div
            matSuffix
            class="icon-suffix"
            matTooltip="{{
              'APP.EVENT_RSVP.DATA_CHANGE_NOT_ALLOWED' | translate
            }}"
            matTooltipPosition="above"
          >
            <i class="fal fa-info-circle"></i>
          </div>
        }
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Gender</mat-label>
        <input type="text" matInput formControlName="gender" />
        @if (communityMember.userId) {
          <div
            matSuffix
            class="icon-suffix"
            matTooltip="{{
              'APP.EVENT_RSVP.DATA_CHANGE_NOT_ALLOWED' | translate
            }}"
            matTooltipPosition="above"
          >
            <i class="fal fa-info-circle"></i>
          </div>
        }
      </mat-form-field>
    }
  </form>
}

<!--<div class="btn-wrapper right-btn">-->
<!--  <button type="button" class="btn btn-primary-party-page" (click)="updateUser()">-->
<!--    {{ "APP.SAVE" | translate }}-->
<!--  </button>-->
<!--</div>-->
