import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnInit,
  PLATFORM_ID,
  SecurityContext,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { CommonModule, DatePipe, isPlatformServer } from '@angular/common';
import { EventAttendeeStatus } from '@models/event-attendance/event-attendee-status.enum';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth/auth.service';
import { EventTemplateVisual } from '@models/design-templates/event-template-visual.model';
import { EventTemplateFont } from '@models/design-templates/event-template-font.model';
import { Event } from '@models/events/event.model';
import { FontService } from '@services/shared/font.service';
import { ScreenWidthService } from '@services/shared/screen-width.service';
import { User } from '@models/users/user.model';
import { AddToCalendarDialog } from '@modules/shared/dialogs/add-to-calendar/add-to-calendar.dialog';
import { MatDialog } from '@angular/material/dialog';
import { EventService } from '@services/events/event.service';
import { EventStore } from '@services/stores/event.store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Helpers } from '@utils/helpers';
import { EventWidget } from '@models/events/event-widget.enum';
import { EventSection } from '@models/events/event-section.model';
import { environment as env } from '@environments/environment';
import { StickyFixedDirective } from 'app/directives/sticky-fixed.directive';
import { RSVPOptionType } from '@models/event-attendance/rsvp-option-type.enum';
import { EventAfterAttendOverlayDialogComponent } from '@modules/events/dialogs/event-after-attend-overlay-dialog/event-after-attend-overlay-dialog.component';
import { NotificationService } from '@services/shared/notification.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { EventAttendee } from '@models/event-attendance/event-attendee.model';
import { SectionEventRsvpDialog } from '@modules/events/dialogs/section-event-rsvp/section-event-rsvp.dialog';
import { DateAmPmPipe } from 'app/pipes/date-am-pm.pipe';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import moment from 'moment';
import 'moment/locale/de';
import { EventSectionType } from '@models/events/event-section-type.enum';
import { MatTooltip, MatTooltipModule } from '@angular/material/tooltip';
import { SafeHtmlPipe } from 'app/pipes/safe-html.pipe';
import { RsvpDialog } from '@modules/events/dialogs/rsvp/rsvp.dialog';
import { EventRSVPOption } from '@models/event-attendance/event-rsvp-option.model';

@Component({
  selector: 'app-invitation-event',
  standalone: true,
  imports: [
    CommonModule,
    MatTooltipModule,
    TranslateModule,
    StickyFixedDirective,
    DateAmPmPipe,
    SafeHtmlPipe,
  ],
  providers: [EventService, ScreenWidthService, NotificationService, DatePipe],
  templateUrl: './invitation-event.component.html',
  styleUrl: './invitation-event.component.scss',
})
export class InvitationEventComponent
  implements OnInit, OnChanges, AfterViewInit
{
  isServer = false;

  private _event?: Event;
  @Input() isVotingView?: boolean = false;

  set event(event: Event) {
    this._event = event;

    if (this.font) {
      const primaryLogoFont = this.font.primaryLogoFont;
      const bodyFont = this.font.bodyFont;
      const fontFamilies: string[] = [];
      if (primaryLogoFont) {
        fontFamilies.push(primaryLogoFont);
      }
      if (bodyFont) {
        fontFamilies.push(bodyFont);
      }
      fontFamilies.push('Bodoni Moda');

      this.fontService.updateFonts(fontFamilies);

      Helpers.setEventFonts(
        this.font.primaryLogoFont,
        this.font.bodyFont,
        this.font.bodyFontWeight,
      );
    }
  }

  get event(): Event | undefined {
    return this._event;
  }

  @Input() isHostView = false;

  @Input() loggedUser?: User;

  eventActionsSticky = false;
  isDesktop = true;
  isNotAttendingUser?: boolean;
  availableCapacity?: number;
  showMaxCapacity?: boolean;
  isCapacity?: boolean;
  userOnWaitList: boolean = false;
  firstLoad = true;

  invitedAttendeeUuid?: string;
  invitedAttendee?: EventAttendee;

  inviteeEmail?: string;
  inviteeFirstName?: string;
  inviteeLastName?: string;

  @ViewChild('keyVisualVideo', { read: ElementRef, static: false })
  keyVisualVideo?: ElementRef;
  @ViewChild('keyVisualOverlayVideo', { read: ElementRef, static: false })
  keyVisualOverlayVideo?: ElementRef;

  @ViewChild('unMuteTooltip', { static: false })
  unMuteTooltip?: MatTooltip;

  isVideoMuted = true;

  hasVideoAutoplayOccurred = false;

  @ViewChild('effectVideo', { read: ElementRef, static: false })
  effectVideo?: ElementRef;

  constructor(
    private eventService: EventService,
    private eventStore: EventStore,
    private fontService: FontService,
    private screenWidthService: ScreenWidthService,
    private dialog: MatDialog,
    private authService: AuthService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private domSanitizer: DomSanitizer,
    private cd: ChangeDetectorRef,
    private viewContainerRef: ViewContainerRef,
    @Inject(PLATFORM_ID) platformId: Object,
  ) {
    this.isServer = isPlatformServer(platformId);

    this.eventStore.event.pipe(takeUntilDestroyed()).subscribe((event) => {
      if (event) {
        this.event = event;

        if (this.firstLoad) {
          this.checkInvitedAttendeeRSVP();
          this.checkEmailRSVP();
          this.firstLoad = false;
        }
      }
    });
    this.screenWidthService
      .isDesktop()
      .pipe(takeUntilDestroyed())
      .subscribe({
        next: (isDesktop: boolean) => {
          this.isDesktop = isDesktop;
        },
      });
    this.screenWidthService
      .isOver992()
      .pipe(takeUntilDestroyed())
      .subscribe({
        next: (isOver992: boolean) => {
          this.eventActionsSticky = !isOver992;
        },
      });

    this.authService.userSubject
      .pipe(takeUntilDestroyed())
      .subscribe((user) => {
        if (user.id > 0) {
          this.loggedUser = user;
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.font && this.isVotingView) {
      Helpers.setEventTheme(
        this.font.primaryLogoFontColor,
        this.font.bodyFontColor,
        this.visual?.backgroundColor,
      );
    }
  }

  ngOnInit(): void {
    const foundAttendee = this.event?.attendees?.find(
      (x) => x.userId === this.loggedUser?.id,
    );
    if (
      foundAttendee &&
      foundAttendee.status === EventAttendeeStatus.ON_WAITLIST
    ) {
      this.userOnWaitList = true;
    }

    this.loggedUser && this.checkIfUserAttending();
    this.calculateMaxCapacity();
    if (this.font && this.isVotingView) {
      Helpers.setEventTheme(
        this.font.primaryLogoFontColor,
        this.font.bodyFontColor,
        this.visual?.backgroundColor,
      );
    }
  }

  ngAfterViewInit() {
    if (this.unMuteTooltip) {
      this.unMuteTooltip.show();
      setTimeout(() => {
        this.unMuteTooltip?.hide();
      }, 3000);
    }
    if (!this.hasVideoAutoplayOccurred) {
      this.checkKeyVisualVideoAutoplay();
      this.checkKeyVisualOverlayVideoAutoplay();
      this.checkEffectVideoAutoplay();
    }
  }

  checkKeyVisualVideoAutoplay(): void {
    if (
      !this.keyVisualVideo ||
      !this.keyVisualVideo.nativeElement ||
      !this.keyVisualVideo.nativeElement.play
    ) {
      return;
    }

    const promise = this.keyVisualVideo.nativeElement.play();
    if (promise) {
      promise.then((_: any) => {
        this.hasVideoAutoplayOccurred = true;
      });
    }
  }

  checkKeyVisualOverlayVideoAutoplay(): void {
    if (
      !this.keyVisualOverlayVideo ||
      !this.keyVisualOverlayVideo.nativeElement ||
      !this.keyVisualOverlayVideo.nativeElement.play
    ) {
      return;
    }

    const promise = this.keyVisualOverlayVideo.nativeElement.play();
    if (promise) {
      promise.then((_: any) => {
        this.hasVideoAutoplayOccurred = true;
      });
    }
  }

  checkEffectVideoAutoplay(): void {
    if (
      !this.effectVideo ||
      !this.effectVideo.nativeElement ||
      !this.effectVideo.nativeElement.play
    ) {
      return;
    }

    const promise = this.effectVideo.nativeElement.play();
    if (promise) {
      promise.then((_: any) => {
        this.hasVideoAutoplayOccurred = true;
      });
    }
  }

  checkInvitedAttendeeRSVP() {
    const snapshot = this.activatedRoute.snapshot;
    const attendeeUuid = snapshot.queryParamMap.get('attendee');

    if (attendeeUuid && attendeeUuid !== '') {
      this.invitedAttendeeUuid = attendeeUuid;
      this.eventService
        .getEventAttendeeByUuid(this.event!.id, this.invitedAttendeeUuid)
        .subscribe((invitedAttendee) => {
          this.invitedAttendee = invitedAttendee;
        });

      const rsvp = snapshot.queryParamMap.get('rsvp');
      if (rsvp && rsvp !== '') {
        const rsvpGoingOrNot = rsvp === 'true';

        if (this.activatedRoute.snapshot.queryParamMap) {
          this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: {
              rsvp: null,
            },
            queryParamsHandling: 'merge',
          });
        }

        if (rsvpGoingOrNot) {
          this.rsvp(RSVPOptionType.YES);
        } else {
          this.rsvp(RSVPOptionType.NO);
        }
      }
    }
  }

  checkEmailRSVP() {
    const snapshot = this.activatedRoute.snapshot;
    const email = snapshot.queryParamMap.get('email');

    if (email && email !== '') {
      this.inviteeEmail = email;

      const firstName = snapshot.queryParamMap.get('firstName');
      if (firstName && firstName !== '') {
        this.inviteeFirstName = firstName;
      }

      const lastName = snapshot.queryParamMap.get('lastName');
      if (lastName && lastName !== '') {
        this.inviteeLastName = lastName;
      }

      const rsvp = snapshot.queryParamMap.get('rsvp');
      if (rsvp && rsvp !== '') {
        const rsvpGoingOrNot = rsvp === 'true';

        if (this.activatedRoute.snapshot.queryParamMap) {
          this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: {
              rsvp: null,
            },
            queryParamsHandling: 'merge',
          });
        }

        if (rsvpGoingOrNot) {
          this.rsvp(RSVPOptionType.YES);
        } else {
          this.rsvp(RSVPOptionType.NO);
        }
      }
    }
  }

  checkGivenEmailRSVP(email?: string, firstName?: string, lastName?: string) {
    if (email && email !== '') {
      this.inviteeEmail = email;

      if (firstName && firstName !== '') {
        this.inviteeFirstName = firstName;
      }

      if (lastName && lastName !== '') {
        this.inviteeLastName = lastName;
      }
    }
  }

  calculateMaxCapacity() {
    if (!this.event?.maxCapacity || this.event.maxCapacity === 0) {
      this.showMaxCapacity = false;
      this.isCapacity = true; // There is no limit
    } else {
      const guestsGoing = this.event.getNumYesAttendees() || 0;
      const availablePlaces = this.event.maxCapacity - guestsGoing;

      if (availablePlaces > 0) {
        this.availableCapacity = availablePlaces;
        this.showMaxCapacity = true;
      } else {
        this.showMaxCapacity = false;
      }

      this.isCapacity = this.showMaxCapacity || guestsGoing === 0;
    }
  }
  checkIfUserAttending() {
    const loggedInUserId = this.loggedUser?.id;
    const eventAttendees = this.event?.attendees?.map((x) => x.user?.id);
    const eventHosts = this.event?.hosts?.map((x) => x.user?.id);

    const isNotHost = this.event?.hostUserId !== loggedInUserId;
    const isNotCoHost = !eventAttendees?.includes(loggedInUserId);
    const isNotAttendee = !eventHosts?.includes(loggedInUserId);

    this.isNotAttendingUser = isNotHost && isNotCoHost && isNotAttendee;
  }

  onRefreshEvent() {
    if (this.event) {
      const params = new Map<string, string>();
      const userLang = localStorage.getItem('userLang');
      if (userLang) {
        params.set('userLang', userLang);
      }
      this.eventService.getByUri(this.event.uri, params).subscribe({
        next: (event) => {
          this.event = event;
        },
      });
    }
  }

  shareEvent() {
    if (this.event) {
      window.navigator.share({
        text: this.event.description,
        title: this.event.name,
        url: this.event.getLink(),
      });
    }
  }

  addToCalendar() {
    this.dialog.open(AddToCalendarDialog, {
      maxWidth: '602px',
      maxHeight: '100vh',
      width: '100%',
      height: 'auto',
      data: this.event,
      panelClass: ['normal-dialog', 'event-dialog', 'overlay-theme'],
    });
  }

  rsvp(rsvpType: RSVPOptionType) {
    if (!this.event) {
      return;
    }

    if (
      [
        'founders-fiesta',
        'eltern-kind-olympiade-2024',
        'woerthsee-vorschulturnen',
        'eventpageai-exit-party',
      ].includes(this.event.uri)
    ) {
      this.onRSVP(rsvpType);
      return;
    }

    const dialogRef = this.dialog.open(SectionEventRsvpDialog, {
      maxWidth: '602px',
      maxHeight: '100vh',
      width: '100%',
      height: 'auto',
      data: {
        event: this.event,
        loggedUser: this.loggedUser,
        invitedAttendeeUuid: this.invitedAttendeeUuid,
        invitedAttendee: this.invitedAttendee,
        inviteeEmail: this.inviteeEmail,
        inviteeFirstName: this.inviteeFirstName,
        inviteeLastName: this.inviteeLastName,
      },
      panelClass: ['section-event-rsvp-dialog'],
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res && this.event) {
        if (res.refresh) {
          this.eventStore.refreshEvent(this.event.uri);
        }

        if (res.attendeeData) {
          const queryParams: Params = {};

          queryParams['email'] = res.attendeeData.email;
          queryParams['firstName'] = res.attendeeData.firstName;
          queryParams['lastName'] = res.attendeeData.lastName;

          this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: queryParams,
            queryParamsHandling: 'merge',
          });

          this.checkGivenEmailRSVP(
            res.attendeeData.email,
            res.attendeeData.firstName,
            res.attendeeData.lastName,
          );
        }

        if (res.openEventAfterAttendOverlayDialog) {
          setTimeout(() => {
            if (this.event) {
              this.dialog.open(EventAfterAttendOverlayDialogComponent, {
                width: '100%',
                height: '100%',
                maxWidth: '100%',
                panelClass: 'overlay-page-dialog',
                data: {
                  event: this.event,
                  rsvpType: res.rsvpType || rsvpType,
                  onPendingList:
                    (!this.attendee && this.event.requireAttendeeApproval) ||
                    (this.attendee &&
                      this.attendee.status ===
                        EventAttendeeStatus.PENDING_APPROVAL),
                },
              });
            }
          });
        }
      }
    });
  }

  onRSVP(type: RSVPOptionType, notGoing?: boolean) {
    if (!this.event) {
      return;
    }

    const dialogRef = this.dialog.open(RsvpDialog, {
      viewContainerRef: this.viewContainerRef,
      maxWidth: '602px',
      maxHeight: '100vh',
      width: '100%',
      height: 'auto',
      data: {
        event: this.event,
        rsvpType: type,
        isAttendee: !!this.userAttendeeRSVPOption,
        invitedAttendeeUuid: this.invitedAttendeeUuid,
        invitedAttendee: this.invitedAttendee,
        inviteeEmail: this.inviteeEmail,
        inviteeFirstName: this.inviteeFirstName,
        inviteeLastName: this.inviteeLastName,
        notGoing: notGoing,
        onWaitlist:
          this.event.maxCapacityReached() && this.event.waitlistEnabled,
      },
      panelClass: ['section-event-rsvp-dialog'],
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res && this.event) {
        if (this.event.isPreviewEvent) {
          return;
        }

        if (res.refresh) {
          this.eventStore.refreshEvent(this.event.uri);
        }

        if (res.attendeeData) {
          const queryParams: Params = {};

          queryParams['email'] = res.attendeeData.email;
          queryParams['firstName'] = res.attendeeData.firstName;
          queryParams['lastName'] = res.attendeeData.lastName;

          this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: queryParams,
            queryParamsHandling: 'merge',
          });

          this.checkGivenEmailRSVP(
            res.attendeeData.email,
            res.attendeeData.firstName,
            res.attendeeData.lastName,
          );
        }

        if (res.openEventAfterAttendOverlayDialog) {
          this.dialog.open(EventAfterAttendOverlayDialogComponent, {
            width: '100%',
            height: '100%',
            maxWidth: '100%',
            panelClass: 'overlay-page-dialog',
            data: {
              event: this.event,
              rsvpType: res.rsvpType || type,
              onWaitlist:
                this.event?.maxCapacityReached() && this.event.waitlistEnabled,
              onPendingList:
                (!this.attendee && this.event.requireAttendeeApproval) ||
                (this.attendee &&
                  this.attendee.status ===
                    EventAttendeeStatus.PENDING_APPROVAL),
            },
          });
        }
      } else {
        if (this.event) {
          this.eventStore.refreshEvent(this.event.uri);
        }
      }
    });
  }

  notGoingRsvp() {
    if (!this.event) {
      return;
    }

    if (
      (this.isAttendeeView && this.attendeeRsvpType === RSVPOptionType.YES) ||
      this.isPendingAttendeeView
    ) {
      if (
        [
          'founders-fiesta',
          'eltern-kind-olympiade-2024',
          'woerthsee-vorschulturnen',
          'eventpageai-exit-party',
        ].includes(this.event.uri)
      ) {
        this.onRSVP(RSVPOptionType.NO, true);
      }
    }
  }

  get userAttendeeRSVPOption(): EventRSVPOption | undefined {
    return this.loggedUser?.id
      ? this.event?.getUserAttendeeRSVPOption(this.loggedUser?.id)
      : undefined;
  }

  get sections(): EventSection[] | undefined {
    return this.event?.eventTemplate?.eventSections;
  }

  get visual(): EventTemplateVisual | undefined {
    return this.event?.eventTemplate?.visual;
  }

  get font(): EventTemplateFont | undefined {
    return this.event?.eventTemplate?.font;
  }

  get isAttendeeView(): boolean {
    return (
      (!!this.loggedUser &&
        !!this.event?.getUserAttendeeRSVPOption(this.loggedUser.id) &&
        this.event.isNonPendingAttendee(this.loggedUser.id)) ||
      (!!this.invitedAttendee &&
        !!this.event?.getAttendeeRSVPOptionById(this.invitedAttendee.id) &&
        this.event.isNonPendingAttendeeById(this.invitedAttendee.id)) ||
      (!!this.inviteeEmail &&
        this.inviteeEmail !== '' &&
        !!this.event?.getAttendeeRSVPOptionByEmail(this.inviteeEmail) &&
        this.event.isNonPendingAttendeeByEmail(this.inviteeEmail))
    );
  }

  get isPendingAttendeeView(): boolean {
    return (
      (!!this.loggedUser &&
        !!this.event?.getUserAttendeeRSVPOption(this.loggedUser.id) &&
        this.event.isPendingAttendee(this.loggedUser.id)) ||
      (!!this.invitedAttendee &&
        !!this.event?.getAttendeeRSVPOptionById(this.invitedAttendee.id) &&
        this.event.isPendingAttendeeById(this.invitedAttendee.id)) ||
      (!!this.inviteeEmail &&
        this.inviteeEmail !== '' &&
        !!this.event?.getAttendeeRSVPOptionByEmail(this.inviteeEmail) &&
        this.event.isPendingAttendeeByEmail(this.inviteeEmail))
    );
  }

  get attendee(): EventAttendee | undefined {
    if (this.loggedUser && this.loggedUser.id) {
      return this.event?.getAttendee(this.loggedUser.id);
    } else if (this.invitedAttendee) {
      return this.event?.getAttendeeById(this.invitedAttendee.id);
    } else if (this.inviteeEmail && this.inviteeEmail !== '') {
      return this.event?.getAttendeeByEmail(this.inviteeEmail);
    }

    return undefined;
  }

  get attendeeRsvpType(): RSVPOptionType | undefined {
    if (this.loggedUser && this.loggedUser.id) {
      return this.event?.getUserAttendeeRSVPOption(this.loggedUser.id)?.type;
    } else if (this.invitedAttendee) {
      return this.event?.getAttendeeRSVPOptionById(this.invitedAttendee.id)
        ?.type;
    } else if (this.inviteeEmail && this.inviteeEmail !== '') {
      return this.event?.getAttendeeRSVPOptionByEmail(this.inviteeEmail)?.type;
    }

    return undefined;
  }

  get widgets(): EventWidget[] | undefined {
    return this.event?.eventTemplate?.widgets;
  }

  get isUserHost(): boolean {
    return (
      !!this.loggedUser &&
      !!this.loggedUser.id &&
      !!this.event &&
      (this.event.isUserHost(this.loggedUser.id) ||
        this.event.isNotLoggedUserHost())
    );
  }

  get userName(): string {
    if (this.invitedAttendee) {
      const name = this.invitedAttendee.getName();
      if (name === '') {
        return this.invitedAttendee.getEmail();
      }
      return this.invitedAttendee.getName();
    } else if (this.inviteeFirstName && this.inviteeFirstName !== '') {
      return this.inviteeFirstName;
    } else if (this.inviteeLastName && this.inviteeLastName !== '') {
      return this.inviteeLastName;
    } else if (this.loggedUser && this.loggedUser.id) {
      return this.loggedUser.getDisplayName();
    } else {
      return 'Guest';
    }
  }

  get eventDescriptionHtml(): string {
    if (!this.event) {
      return 'Guest';
    }
    if (this.event && this.event.description) {
      return this.event.description.replace('{{userName}}', this.userName);
    }
    return '';
  }

  getFormattedDate(date: Date): string {
    const toFormat = moment(date);
    toFormat.locale(this.translateService.currentLang);

    if (this.event?.is12HourDateFormat()) {
      return toFormat.format('dddd, MMMM Do');
    }
    return toFormat.format('dddd, DD.MM.YYYY');
  }

  onUnmuteVideo() {
    if (!this.keyVisualVideo || !this.keyVisualVideo.nativeElement) {
      return;
    }

    this.keyVisualVideo.nativeElement.muted = false;
    this.isVideoMuted = false;
    this.cd.detectChanges();
  }

  onMuteVideo() {
    if (!this.keyVisualVideo || !this.keyVisualVideo.nativeElement) {
      return;
    }

    this.keyVisualVideo.nativeElement.muted = true;
    this.isVideoMuted = true;
    this.cd.detectChanges();
  }

  checkIsEmptyHtml(html: SafeHtml): boolean {
    const stringHtml = this.domSanitizer.sanitize(SecurityContext.HTML, html);

    if (!stringHtml) {
      return true;
    }
    return (
      stringHtml === '' ||
      stringHtml === '<p></p>' ||
      stringHtml === '<p><br class="softbreak"></p>'
    );
  }

  get visualEffectUrl(): string | undefined {
    if (!this.visual?.effect) {
      return undefined;
    }

    if (this.isDesktop || this.visual?.effect?.mobileUrl === '') {
      return this.visual?.effect?.url;
    } else {
      return this.visual?.effect?.mobileUrl;
    }
  }

  get visualEffectHevcUrl(): string | undefined {
    if (!this.visual?.effect) {
      return undefined;
    }

    if (this.isDesktop || this.visual?.effect?.h265MobileUrl === '') {
      return this.visual?.effect?.h265Url;
    } else {
      return this.visual?.effect?.h265MobileUrl;
    }
  }

  private checkAndShowRSVPError(err: any) {
    if (err?.error === 'bad request - max capacity exceeded') {
      this.notificationService.error(
        this.translateService.instant('APP.EVENT_RSVP.ERRORS.MAX_CAPACITY'),
        this.translateService.instant('APP.EVENT_RSVP.ERRORS.COULDNT_RSVP'),
      );
    } else if (err?.error === 'bad request - rsvp disabled') {
      this.notificationService.error(
        this.translateService.instant('APP.EVENT_RSVP.ERRORS.DISABLED'),
        this.translateService.instant('APP.EVENT_RSVP.ERRORS.COULDNT_RSVP'),
      );
    } else if (err?.error === 'bad request - plus ones limit exceeded') {
      this.notificationService.error(
        this.translateService.instant('APP.EVENT_RSVP.ERRORS.PLUS_ONES_LIMIT'),
        this.translateService.instant('APP.EVENT_RSVP.ERRORS.COULDNT_RSVP'),
      );
    } else {
      this.notificationService.error(
        this.translateService.instant('APP.EVENT_RSVP.ERRORS.COULDNT_RSVP'),
      );
    }
  }

  @HostListener('document:touchstart', ['$event'])
  onTouchStart(event: Event): void {
    if (!this.hasVideoAutoplayOccurred) {
      this.hasVideoAutoplayOccurred = true;
      this.checkKeyVisualVideoAutoplay();
      this.checkKeyVisualOverlayVideoAutoplay();
      this.checkEffectVideoAutoplay();
    }
  }

  get userTheme(): string {
    const userTheme = localStorage.getItem('userTheme');

    if (userTheme) {
      return userTheme;
    }

    return 'light';
  }

  protected readonly EventWidget = EventWidget;
  protected readonly RSVPOptionType = RSVPOptionType;
  protected readonly env = env;
  protected readonly EventSectionType = EventSectionType;
}
